@charset "UTF-8";
html {
  position: relative;
  min-height: 100%; }

body {
  height: 100%; }

a:focus {
  outline: 0; }

#wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.875rem;
  /*

.input-group-append span {
  -webkit-box-shadow: 0 .125rem .25rem 0 rgba(58, 59, 69, .2) !important;
  box-shadow: 0 .125rem .25rem 0 rgba(58, 59, 69, .2) !important;
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5
}
*/ }
  #wrapper .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "파일첨부"; }
  #wrapper .btn {
    font-size: 0.875rem; }
  #wrapper .form-control {
    font-size: 0.875rem; }
  #wrapper .input-group-text {
    font-size: 0.875rem; }
  #wrapper .form-select {
    font-size: 0.875rem; }
  #wrapper #content-wrapper {
    background-color: #fafdfb;
    width: 100%;
    overflow-x: hidden; }
  #wrapper #content-wrapper #content {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }
  #wrapper .container,
  #wrapper .container-fluid,
  #wrapper .container-login {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  #wrapper .scroll-to-top {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    display: none;
    width: 2.75rem;
    height: 2.75rem;
    text-align: center;
    color: #fff;
    background: rgba(90, 92, 105, 0.5);
    line-height: 46px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
  #wrapper .scroll-to-top:focus,
  #wrapper .scroll-to-top:hover {
    color: #fff;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
  #wrapper .scroll-to-top:hover {
    background: #5a5c69;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
  #wrapper .scroll-to-top i {
    font-weight: 800; }

@-webkit-keyframes growIn {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes growIn {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }
  #wrapper .animated--grow-in,
  #wrapper .sidebar .nav-item .collapse {
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    /*-webkit-animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
  animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1)*/ }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  #wrapper .animated--fade-in {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    /*-webkit-animation-timing-function: opacity cubic-bezier(0, 1, .4, 1);
  animation-timing-function: opacity cubic-bezier(0, 1, .4, 1)*/ }
  #wrapper .bg-gradient-primary {
    background-color: #6777ef;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #6777ef), to(#303f9f));
    background-image: linear-gradient(180deg, #6777ef 10%, #303f9f 100%);
    background-size: cover; }
  #wrapper .bg-gradient-secondary {
    background-color: #757575;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #757575), to(#60616f));
    background-image: linear-gradient(180deg, #757575 10%, #60616f 100%);
    background-size: cover; }
  #wrapper .bg-gradient-success {
    background-color: #66bb6a;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #66bb6a), to(#13855c));
    background-image: linear-gradient(180deg, #66bb6a 10%, #13855c 100%);
    background-size: cover; }
  #wrapper .bg-gradient-info {
    background-color: #3abaf4;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #3abaf4), to(#258391));
    background-image: linear-gradient(180deg, #3abaf4 10%, #258391 100%);
    background-size: cover; }
  #wrapper .bg-gradient-warning {
    background-color: #ffa426;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #ffa426), to(#dda20a));
    background-image: linear-gradient(180deg, #f6c23e 10%, #ffa426 100%);
    background-size: cover; }
  #wrapper .bg-gradient-danger {
    background-color: #fc544b;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #fc544b), to(#be2617));
    background-image: linear-gradient(180deg, #fc544b 10%, #be2617 100%);
    background-size: cover; }
  #wrapper .bg-gradient-light {
    background-color: #e3eaef;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #e3eaef), to(#c2cbe5));
    background-image: linear-gradient(180deg, #e3eaef 10%, #c2cbe5 100%);
    background-size: cover; }
  #wrapper .bg-gradient-dark {
    background-color: #212121;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #212121), to(#373840));
    background-image: linear-gradient(180deg, #212121 10%, #373840 100%);
    background-size: cover; }
  #wrapper .bg-gradient-default {
    background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important; }
  #wrapper .bg-gradient-login {
    background-color: #f8f9fc;
    background-size: cover; }
  #wrapper .bg-gray-100 {
    background-color: #f8f9fc !important; }
  #wrapper .bg-gray-200 {
    background-color: #eaecf4 !important; }
  #wrapper .bg-gray-300 {
    background-color: #dddfeb !important; }
  #wrapper .bg-gray-400 {
    background-color: #d1d3e2 !important; }
  #wrapper .bg-gray-500 {
    background-color: #b7b9cc !important; }
  #wrapper .bg-gray-600 {
    background-color: #858796 !important; }
  #wrapper .bg-gray-700 {
    background-color: #6e707e !important; }
  #wrapper .bg-gray-800 {
    background-color: #5a5c69 !important; }
  #wrapper .bg-gray-900 {
    background-color: #3a3b45 !important; }
  #wrapper .o-hidden {
    overflow: hidden !important; }
  #wrapper .text-xs {
    font-size: .7rem; }
  #wrapper .text-lg {
    font-size: 1.2rem; }
  #wrapper .text-gray-100 {
    color: #f8f9fc !important; }
  #wrapper .text-gray-200 {
    color: #eaecf4 !important; }
  #wrapper .text-gray-300 {
    color: #dddfeb !important; }
  #wrapper .text-gray-400 {
    color: #d1d3e2 !important; }
  #wrapper .text-gray-500 {
    color: #b7b9cc !important; }
  #wrapper .text-gray-600 {
    color: #858796 !important; }
  #wrapper .text-gray-700 {
    color: #6e707e !important; }
  #wrapper .text-gray-800 {
    color: #5a5c69 !important; }
  #wrapper .text-gray-900 {
    color: #3a3b45 !important; }
  #wrapper .icon-circle {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  #wrapper .border-left-primary {
    border-left: .25rem solid #3f51b5 !important; }
  #wrapper .border-bottom-primary {
    border-bottom: .25rem solid #3f51b5 !important; }
  #wrapper .border-left-secondary {
    border-left: .25rem solid #757575 !important; }
  #wrapper .border-bottom-secondary {
    border-bottom: .25rem solid #757575 !important; }
  #wrapper .border-left-success {
    border-left: .25rem solid #66bb6a !important; }
  #wrapper .border-bottom-success {
    border-bottom: .25rem solid #66bb6a !important; }
  #wrapper .border-left-info {
    border-left: .25rem solid #4dd0e1 !important; }
  #wrapper .border-bottom-info {
    border-bottom: .25rem solid #4dd0e1 !important; }
  #wrapper .border-left-warning {
    border-left: .25rem solid #ffeb3b !important; }
  #wrapper .border-bottom-warning {
    border-bottom: .25rem solid #ffeb3b !important; }
  #wrapper .border-left-danger {
    border-left: .25rem solid #ef5350 !important; }
  #wrapper .border-bottom-danger {
    border-bottom: .25rem solid #ef5350 !important; }
  #wrapper .border-left-light {
    border-left: .25rem solid #f8f9fc !important; }
  #wrapper .border-bottom-light {
    border-bottom: .25rem solid #f8f9fc !important; }
  #wrapper .border-left-dark {
    border-left: .25rem solid #5a5c69 !important; }
  #wrapper .border-bottom-dark {
    border-bottom: .25rem solid #5a5c69 !important; }
  #wrapper .progress-sm {
    height: .5rem; }
  #wrapper .rotate-15 {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  #wrapper .rotate-n-15 {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg); }
  #wrapper .dropdown .dropdown-menu {
    font-size: .85rem;
    -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important; }
  #wrapper .dropdown .dropdown-menu .dropdown-header {
    font-weight: 600;
    font-size: .65rem;
    color: #b7b9cc; }
  #wrapper .dropdown.no-arrow .dropdown-toggle::after {
    display: none; }
  #wrapper .sidebar .nav-item.dropdown .dropdown-toggle::after,
  #wrapper .topbar .nav-item.dropdown .dropdown-toggle::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f105'; }
  #wrapper .sidebar .nav-item.dropdown.show .dropdown-toggle::after,
  #wrapper .topbar .nav-item.dropdown.show .dropdown-toggle::after {
    content: '\f107'; }
  #wrapper .sidebar .nav-item .nav-link,
  #wrapper .topbar .nav-item .nav-link {
    position: relative; }
  #wrapper .sidebar .nav-item .nav-link .badge-counter,
  #wrapper .topbar .nav-item .nav-link .badge-counter {
    position: absolute;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-transform-origin: top right;
    transform-origin: top right;
    right: .25rem;
    margin-top: -.25rem; }
  #wrapper .sidebar .nav-item .nav-link .img-profile,
  #wrapper .topbar .nav-item .nav-link .img-profile {
    height: 2rem;
    width: 2rem;
    border: 1px solid #fafafa; }
  #wrapper .topbar {
    height: 4.375rem;
    z-index: 2;
    -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important; }
  #wrapper .topbar #sidebarToggleTop {
    height: 2.5rem;
    width: 2.5rem;
    -webkit-box-shadow: 0 0 !important;
    box-shadow: 0 0 !important; }
  #wrapper .topbar #sidebarToggleTop:hover {
    background-color: #fafafa; }
  #wrapper .topbar #sidebarToggleTop:active {
    background-color: #dddfeb; }
  #wrapper .topbar .navbar-search {
    width: 25rem; }
  #wrapper .topbar .navbar-search input {
    font-size: .85rem; }
  #wrapper .topbar .topbar-divider {
    width: 0;
    border-right: 1px solid #e3e6f0;
    height: calc(4.375rem - 2rem);
    margin: auto 1rem; }
  #wrapper .topbar .nav-item .nav-link {
    height: 4.375rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 .75rem; }
  #wrapper .topbar .nav-item .nav-link:focus {
    outline: 0; }
  #wrapper .topbar .nav-item:focus {
    outline: 0; }
  #wrapper .topbar .dropdown {
    position: static; }
  #wrapper .topbar .dropdown .dropdown-menu {
    width: calc(100% - 1.5rem);
    right: .75rem; }
  #wrapper .topbar .dropdown-list {
    padding: 0;
    border: none;
    overflow: hidden; }
  #wrapper .topbar .dropdown-list .dropdown-header {
    background-color: #3f51b5;
    border: 1px solid #3f51b5;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #fff; }
  #wrapper .topbar .dropdown-list .dropdown-item {
    white-space: normal;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-left: 1px solid #e3e6f0;
    border-right: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
    line-height: 1.3rem; }
  #wrapper .topbar .dropdown-list .dropdown-item .dropdown-list-image {
    position: relative;
    height: 2.5rem;
    width: 2.5rem; }
  #wrapper .topbar .dropdown-list .dropdown-item .dropdown-list-image img {
    height: 2.5rem;
    width: 2.5rem; }
  #wrapper .topbar .dropdown-list .dropdown-item .dropdown-list-image .status-indicator {
    background-color: #eaecf4;
    height: .75rem;
    width: .75rem;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    border: .125rem solid #fff; }
  #wrapper .topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 10rem; }
  #wrapper .topbar .dropdown-list .dropdown-item:active {
    background-color: #eaecf4;
    color: #3a3b45; }
  @media (min-width: 576px) {
    #wrapper .topbar .dropdown {
      position: relative; }
    #wrapper .topbar .dropdown .dropdown-menu {
      width: auto;
      right: 0; }
    #wrapper .topbar .dropdown-list {
      width: 20rem !important; }
    #wrapper .topbar .dropdown-list .dropdown-item .text-truncate {
      max-width: 13.375rem; } }
  #wrapper .topbar.navbar-light .navbar-nav .nav-item .nav-link {
    color: #fafafa;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
  #wrapper .topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
    color: #e0e0e0;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
  #wrapper .topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
    color: #858796; }
  #wrapper .bg-navbar {
    background-color: #6777ef; }
  #wrapper .sidebar {
    min-height: 100vh;
    width: 14rem !important;
    background-color: #fff !important;
    -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    z-index: 0; }
  #wrapper .sidebar .nav-item {
    position: relative;
    margin-bottom: -15px; }
  #wrapper .sidebar .nav-item:last-child {
    margin-bottom: 1rem; }
  #wrapper .sidebar .nav-item .nav-link {
    display: block;
    width: 100%;
    text-align: left;
    padding: 1rem; }
  #wrapper .sidebar .nav-item .nav-link i {
    font-size: .85rem;
    margin-right: .25rem; }
  #wrapper .sidebar .nav-item .nav-link span {
    font-size: .85rem;
    display: inline; }
  #wrapper .sidebar .nav-item.active .nav-link {
    font-weight: 800; }
  #wrapper .sidebar .nav-item .nav-link[data-toggle=collapse]::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f107'; }
  #wrapper .sidebar .nav-item .nav-link[data-toggle=collapse].collapsed::after {
    content: '\f105'; }
  #wrapper .sidebar .nav-item .collapse {
    position: relative;
    left: 0;
    z-index: 1;
    top: 0;
    -webkit-animation: none;
    animation: none; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner {
    border-radius: .35rem;
    -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out; }
  #wrapper .sidebar .nav-item .collapsing {
    display: block;
    -webkit-transition: height .3s ease;
    transition: height .3s ease; }
  #wrapper .sidebar .nav-item .collapse,
  #wrapper .sidebar .nav-item .collapsing {
    margin: 0 1rem; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner,
  #wrapper .sidebar .nav-item .collapsing .collapse-inner {
    padding: .5rem 0;
    min-width: 10rem;
    font-size: .85rem;
    margin: 0 0 1rem 0; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner .collapse-header,
  #wrapper .sidebar .nav-item .collapsing .collapse-inner .collapse-header {
    margin: 0;
    white-space: nowrap;
    padding: .5rem 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .65rem;
    color: #b7b9cc; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner .collapse-item,
  #wrapper .sidebar .nav-item .collapsing .collapse-inner .collapse-item {
    padding: .5rem 1rem;
    margin: 0 .5rem;
    display: block;
    color: #3a3b45;
    text-decoration: none;
    border-radius: .35rem;
    white-space: nowrap; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
  #wrapper .sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
    background-color: #eaecf4;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner .collapse-item:active,
  #wrapper .sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
    background-color: #dddfeb; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
  #wrapper .sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
    color: #3a3b45;
    font-weight: 800; }
  #wrapper .sidebar #sidebarToggle {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    cursor: pointer; }
  #wrapper .sidebar #sidebarToggle::after {
    font-weight: 900;
    content: '\f104';
    margin-right: .1rem; }
  #wrapper .sidebar #sidebarToggle:hover {
    text-decoration: none; }
  #wrapper .sidebar #sidebarToggle:focus {
    outline: 0; }
  #wrapper .sidebar .sidebar-brand {
    height: 4.375rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    padding: 1.5rem 1rem;
    text-align: center;
    letter-spacing: .05rem;
    z-index: 1; }
  #wrapper .sidebar .sidebar-brand .sidebar-brand-icon img {
    max-height: 2.3rem; }
  #wrapper .sidebar hr.sidebar-divider {
    margin: 1rem 0 1rem 0; }
  #wrapper .sidebar .sidebar-heading {
    text-align: left;
    padding: 0 1rem;
    font-weight: 600;
    font-size: .75rem;
    letter-spacing: .13rem; }
  #wrapper .sidebar .version {
    text-align: left;
    font-size: .6rem;
    padding: 0 1rem;
    color: #b7b9cc;
    letter-spacing: .030rem; }
  #wrapper .sidebar .sidebar-brand .sidebar-brand-text {
    display: inline; }
  #wrapper .sidebar.toggled {
    overflow: visible;
    width: 6.5rem !important;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    z-index: 1; }
  #wrapper .sidebar.toggled .nav-item {
    margin-bottom: 0; }
  #wrapper .sidebar.toggled hr.sidebar-divider {
    margin: 0 0 1rem 0; }
  #wrapper .sidebar.toggled #sidebarToggle::after {
    content: '\f105';
    margin-left: .25rem; }
  #wrapper .sidebar.toggled .nav-item .collapse {
    position: absolute;
    left: calc(6.5rem + 1.5rem / 2);
    top: 2px;
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    /*-webkit-animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
  animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1)*/ }
  #wrapper .sidebar.toggled .nav-item .collapse,
  #wrapper .sidebar.toggled .nav-item .collapsing {
    margin: 0; }
  #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner {
    -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: .35rem; }
  #wrapper .sidebar.toggled .nav-item .collapsing {
    display: none;
    -webkit-transition: none;
    transition: none; }
  #wrapper .sidebar.toggled .nav-item:last-child {
    margin-bottom: 1rem; }
  #wrapper .sidebar.toggled .nav-item .nav-link {
    text-align: center;
    padding: .75rem 1rem;
    width: 6.5rem; }
  #wrapper .sidebar.toggled .nav-item .nav-link {
    background-color: transparent !important; }
  #wrapper .sidebar.toggled .sidebar-heading {
    display: none; }
  #wrapper .sidebar.toggled .nav-item .nav-link span {
    display: none; }
  #wrapper .sidebar.toggled .nav-item .nav-link i {
    float: left;
    text-align: center;
    font-size: 1rem;
    margin: 0 0 .7rem 1rem; }
  #wrapper .sidebar.toggled .sidebar-brand .sidebar-brand-text {
    display: none; }
  #wrapper .sidebar.toggled .nav-item .nav-link[data-toggle=collapse]::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f107'; }
  #wrapper .sidebar.toggled .nav-item .nav-link[data-toggle=collapse].collapsed::after {
    content: '\f105'; }
  #wrapper .login-form {
    padding: 3rem !important; }
  @media (max-width: 768px) {
    #wrapper #container-wrapper {
      min-width: 100vw; }
    #wrapper .sidebar.toggled {
      width: 100vw !important;
      transition: all .3s ease-in-out;
      -webkit-transition: all .3s ease-in-out; }
    #wrapper .sidebar.toggled .nav-item {
      margin-left: -1rem;
      position: relative;
      margin-bottom: -15px; }
    #wrapper .sidebar.toggled .nav-item .nav-link {
      display: block;
      width: 100%;
      text-align: left;
      padding: 1rem; }
    #wrapper .sidebar.toggled .nav-item .nav-link i {
      font-size: .85rem;
      margin-top: .4rem;
      margin-right: .5rem; }
    #wrapper .sidebar.toggled .nav-item .nav-link span {
      font-size: .85rem;
      display: inline; }
    #wrapper .sidebar.toggled .nav-item.active .nav-link {
      font-weight: 700; }
    #wrapper .sidebar.toggled .nav-item .nav-link[data-toggle=collapse]::after {
      width: 1rem;
      text-align: center;
      float: right;
      vertical-align: 0;
      border: 0;
      font-weight: 900;
      content: '\f107'; }
    #wrapper .sidebar.toggled .nav-item .nav-link[data-toggle=collapse].collapsed::after {
      content: '\f105'; }
    #wrapper .sidebar.toggled .nav-item .collapse {
      position: relative;
      padding-left: 1rem;
      left: 0;
      z-index: 1;
      top: 0;
      -webkit-transition: height .15s ease;
      transition: height .15s ease; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner {
      border-radius: .35rem;
      -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
      box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15); }
    #wrapper .sidebar.toggled .nav-item .collapse,
    #wrapper .sidebar.toggled .nav-item .collapsing {
      margin: 0 1rem; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner,
    #wrapper .sidebar.toggled .nav-item .collapsing .collapse-inner {
      padding: .5rem 0;
      min-width: 10rem;
      font-size: .85rem;
      margin: 0 0 1rem 0; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner .collapse-header,
    #wrapper .sidebar.toggled .nav-item .collapsing .collapse-inner .collapse-header {
      margin: 0;
      white-space: nowrap;
      padding: .5rem 1.5rem;
      text-transform: uppercase;
      font-weight: 600;
      font-size: .65rem;
      color: #b7b9cc; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner .collapse-item,
    #wrapper .sidebar.toggled .nav-item .collapsing .collapse-inner .collapse-item {
      padding: .5rem 1rem;
      margin: 0 .5rem;
      display: block;
      color: #3a3b45;
      text-decoration: none;
      border-radius: .35rem;
      white-space: nowrap; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner .collapse-item:hover,
    #wrapper .sidebar.toggled .nav-item .collapsing .collapse-inner .collapse-item:hover {
      background-color: #eaecf4;
      transition: all .3s ease-in-out;
      -webkit-transition: all .3s ease-in-out; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner .collapse-item:active,
    #wrapper .sidebar.toggled .nav-item .collapsing .collapse-inner .collapse-item:active {
      background-color: #dddfeb; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner .collapse-item.active,
    #wrapper .sidebar.toggled .nav-item .collapsing .collapse-inner .collapse-item.active {
      color: #3f51b5;
      font-weight: 800; }
    #wrapper .sidebar.toggled .sidebar-brand {
      height: 4.375rem;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 600;
      padding: 1.5rem 1rem;
      text-align: center;
      letter-spacing: .05rem;
      z-index: 1; }
    #wrapper .sidebar.toggled hr.sidebar-divider {
      margin: 1rem 0 1rem 0; }
    #wrapper .sidebar.toggled .sidebar-heading {
      display: block;
      text-align: left;
      padding: 0 1rem;
      font-weight: 600;
      font-size: .75rem;
      letter-spacing: .13rem; }
    #wrapper .sidebar.toggled .sidebar-brand .sidebar-brand-text {
      display: inline; }
    #wrapper .sidebar {
      width: 0 !important;
      overflow: hidden;
      transition: all .3s ease;
      -webkit-transition: all .3s ease; }
    #wrapper .sidebar .sidebar-brand {
      overflow: hidden; }
    #wrapper .sidebar .sidebar-brand .sidebar-brand-text {
      overflow: hidden; }
    #wrapper .sidebar .nav-item {
      overflow: hidden; }
    #wrapper .sidebar .sidebar-brand .sidebar-brand-icon img {
      overflow: hidden; }
    #wrapper .sidebar hr.sidebar-divider {
      margin: 1rem 0 1rem 0; }
    #wrapper .topbar .navbar-search {
      width: 100%; }
    #wrapper .login-form {
      padding: 3rem 2rem !important; } }
  #wrapper .breadcrumb {
    background-color: transparent;
    font-size: 12px;
    font-weight: 700; }
  #wrapper .sidebar-light .sidebar-brand {
    color: #fafafa;
    background-color: #4c60da; }
  #wrapper .sidebar-light hr.sidebar-divider {
    border-top: 1px solid #eaecf4; }
  #wrapper .sidebar-light .sidebar-heading {
    color: #b7b9cc; }
  #wrapper .sidebar-light .nav-item .nav-link {
    color: #858796; }
  #wrapper .sidebar-light .nav-item .nav-link i {
    color: #9e9e9e; }
  #wrapper .sidebar-light .nav-item .nav-link:active,
  #wrapper .sidebar-light .nav-item .nav-link:focus,
  #wrapper .sidebar-light .nav-item .nav-link:hover {
    color: #6e707e;
    background-color: #eee; }
  #wrapper .sidebar-light .nav-item .nav-link:active i,
  #wrapper .sidebar-light .nav-item .nav-link:focus i,
  #wrapper .sidebar-light .nav-item .nav-link:hover i {
    color: #6e707e; }
  #wrapper .sidebar-light .nav-item .nav-link[data-toggle=collapse]::after {
    color: #b7b9cc; }
  #wrapper .sidebar-light .nav-item.active .nav-link {
    color: #5b5c67;
    /*background-color: #6777ef;*/ }
  #wrapper .sidebar-light .nav-item.active .nav-link i {
    color: #6e707e; }
  #wrapper .sidebar-light #sidebarToggle {
    background-color: #eaecf4; }
  #wrapper .sidebar-light #sidebarToggle::after {
    color: #b7b9cc; }
  #wrapper .sidebar-light #sidebarToggle:hover {
    background-color: #dddfeb; }
  #wrapper .sidebar-dark .sidebar-brand {
    color: #fff; }
  #wrapper .sidebar-dark hr.sidebar-divider {
    border-top: 1px solid rgba(255, 255, 255, 0.15); }
  #wrapper .sidebar-dark .sidebar-heading {
    color: rgba(255, 255, 255, 0.4); }
  #wrapper .sidebar-dark .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.8); }
  #wrapper .sidebar-dark .nav-item .nav-link i {
    color: #d1d3e2; }
  #wrapper .sidebar-dark .nav-item .nav-link:active,
  #wrapper .sidebar-dark .nav-item .nav-link:focus,
  #wrapper .sidebar-dark .nav-item .nav-link:hover {
    color: #fff; }
  #wrapper .sidebar-dark .nav-item .nav-link:active i,
  #wrapper .sidebar-dark .nav-item .nav-link:focus i,
  #wrapper .sidebar-dark .nav-item .nav-link:hover i {
    color: #fff; }
  #wrapper .sidebar-dark .nav-item .nav-link[data-toggle=collapse]::after {
    color: rgba(255, 255, 255, 0.5); }
  #wrapper .sidebar-dark .nav-item.active .nav-link {
    color: #fff; }
  #wrapper .sidebar-dark .nav-item.active .nav-link i {
    color: #fff; }
  #wrapper .sidebar-dark #sidebarToggle {
    background-color: rgba(255, 255, 255, 0.2); }
  #wrapper .sidebar-dark #sidebarToggle::after {
    color: rgba(255, 255, 255, 0.5); }
  #wrapper .sidebar-dark #sidebarToggle:hover {
    background-color: rgba(255, 255, 255, 0.25); }
  #wrapper .sidebar-dark.toggled #sidebarToggle::after {
    color: rgba(255, 255, 255, 0.5); }
  #wrapper .btn-icon-split {
    padding: 0;
    overflow: hidden;
    /*display: -webkit-inline-box;*/
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  #wrapper .btn-icon-split .icon {
    background: rgba(0, 0, 0, 0.15);
    display: inline-block;
    padding: .375rem .75rem; }
  #wrapper .btn-icon-split .text {
    display: inline-block;
    padding: .375rem .75rem; }
  #wrapper .btn-group-sm > .btn-icon-split.btn .icon,
  #wrapper .btn-icon-split.btn-sm .icon {
    padding: .25rem .5rem; }
  #wrapper .btn-group-sm > .btn-icon-split.btn .text,
  #wrapper .btn-icon-split.btn-sm .text {
    padding: .25rem .5rem; }
  #wrapper .btn-group-lg > .btn-icon-split.btn .icon,
  #wrapper .btn-icon-split.btn-lg .icon {
    padding: .5rem 1rem; }
  #wrapper .btn-group-lg > .btn-icon-split.btn .text,
  #wrapper .btn-icon-split.btn-lg .text {
    padding: .5rem 1rem; }
  #wrapper .card .card-header .dropdown {
    line-height: 1; }
  #wrapper .card .card-header .dropdown .dropdown-menu {
    line-height: 1.5; }
  #wrapper .card .card-header[data-toggle=collapse] {
    text-decoration: none;
    position: relative;
    padding: .75rem 3.25rem .75rem 1.25rem; }
  #wrapper .card .card-header[data-toggle=collapse]::after {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 1.725rem;
    line-height: 51px;
    font-weight: 900;
    content: '\f107'; }
  #wrapper .card .card-header[data-toggle=collapse].collapsed {
    border-radius: .35rem; }
  #wrapper .card .card-header[data-toggle=collapse].collapsed::after {
    content: '\f105'; }
  #wrapper .chart-area {
    position: relative;
    height: 10rem;
    width: 100%; }
  @media (min-width: 768px) {
    #wrapper .chart-area {
      height: 20rem; } }
  #wrapper .chart-bar {
    position: relative;
    height: 10rem;
    width: 100%; }
  @media (min-width: 768px) {
    #wrapper .chart-bar {
      height: 20rem; } }
  #wrapper .chart-pie {
    position: relative;
    height: 15rem;
    width: 100%; }
  @media (min-width: 768px) {
    #wrapper .chart-pie {
      height: calc(20rem - 43px) !important; } }
  #wrapper .bg-login-image {
    background-position: center;
    background-size: cover; }
  #wrapper .bg-register-image {
    background-position: center;
    background-size: cover; }
  #wrapper .bg-password-image {
    background-position: center;
    background-size: cover; }
  #wrapper .btn-google {
    color: #434334;
    background-color: #fff;
    border-color: #bdbdbd;
    -webkit-box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    border-radius: .25rem; }
  #wrapper .btn-google:hover {
    color: #434343;
    background-color: #f5f5f5;
    border-color: #434343; }
  #wrapper .btn-google.focus,
  #wrapper .btn-google:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  #wrapper .btn-google.disabled,
  #wrapper .btn-google:disabled {
    color: #434343;
    background-color: #eee;
    border-color: #434334; }
  #wrapper .btn-google:not(:disabled):not(.disabled).active,
  #wrapper .btn-google:not(:disabled):not(.disabled):active,
  #wrapper .show > .btn-google.dropdown-toggle {
    color: #434334;
    background-color: #eee;
    border-color: #434343; }
  #wrapper .btn-google:not(:disabled):not(.disabled).active:focus,
  #wrapper .btn-google:not(:disabled):not(.disabled):active:focus,
  #wrapper .show > .btn-google.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  #wrapper .btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: #fff;
    -webkit-box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    border-radius: .25rem; }
  #wrapper .btn-facebook:hover {
    color: #fff;
    background-color: #30497c;
    border-color: #e6e6e6; }
  #wrapper .btn-facebook.focus,
  #wrapper .btn-facebook:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  #wrapper .btn-facebook.disabled,
  #wrapper .btn-facebook:disabled {
    color: #fff;
    background-color: #3b5998;
    border-color: #fff; }
  #wrapper .btn-facebook:not(:disabled):not(.disabled).active,
  #wrapper .btn-facebook:not(:disabled):not(.disabled):active,
  #wrapper .show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4373;
    border-color: #dfdfdf; }
  #wrapper .btn-facebook:not(:disabled):not(.disabled).active:focus,
  #wrapper .btn-facebook:not(:disabled):not(.disabled):active:focus,
  #wrapper .show > .btn-facebook.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  #wrapper .error {
    color: #5a5c69;
    font-size: 7rem;
    position: relative;
    line-height: 1;
    width: 12.5rem; }

@-webkit-keyframes noise-anim {
  0% {
    clip: rect(31px, 9999px, 91px, 0); }
  5% {
    clip: rect(70px, 9999px, 29px, 0); }
  10% {
    clip: rect(3px, 9999px, 56px, 0); }
  15% {
    clip: rect(7px, 9999px, 78px, 0); }
  20% {
    clip: rect(97px, 9999px, 4px, 0); }
  25% {
    clip: rect(24px, 9999px, 60px, 0); }
  30% {
    clip: rect(80px, 9999px, 41px, 0); }
  35% {
    clip: rect(78px, 9999px, 14px, 0); }
  40% {
    clip: rect(20px, 9999px, 23px, 0); }
  45% {
    clip: rect(70px, 9999px, 61px, 0); }
  50% {
    clip: rect(65px, 9999px, 89px, 0); }
  55% {
    clip: rect(74px, 9999px, 25px, 0); }
  60% {
    clip: rect(76px, 9999px, 84px, 0); }
  65% {
    clip: rect(56px, 9999px, 10px, 0); }
  70% {
    clip: rect(85px, 9999px, 58px, 0); }
  75% {
    clip: rect(46px, 9999px, 71px, 0); }
  80% {
    clip: rect(6px, 9999px, 64px, 0); }
  85% {
    clip: rect(20px, 9999px, 84px, 0); }
  90% {
    clip: rect(57px, 9999px, 26px, 0); }
  95% {
    clip: rect(36px, 9999px, 92px, 0); }
  100% {
    clip: rect(12px, 9999px, 53px, 0); } }

@keyframes noise-anim {
  0% {
    clip: rect(31px, 9999px, 91px, 0); }
  5% {
    clip: rect(70px, 9999px, 29px, 0); }
  10% {
    clip: rect(3px, 9999px, 56px, 0); }
  15% {
    clip: rect(7px, 9999px, 78px, 0); }
  20% {
    clip: rect(97px, 9999px, 4px, 0); }
  25% {
    clip: rect(24px, 9999px, 60px, 0); }
  30% {
    clip: rect(80px, 9999px, 41px, 0); }
  35% {
    clip: rect(78px, 9999px, 14px, 0); }
  40% {
    clip: rect(20px, 9999px, 23px, 0); }
  45% {
    clip: rect(70px, 9999px, 61px, 0); }
  50% {
    clip: rect(65px, 9999px, 89px, 0); }
  55% {
    clip: rect(74px, 9999px, 25px, 0); }
  60% {
    clip: rect(76px, 9999px, 84px, 0); }
  65% {
    clip: rect(56px, 9999px, 10px, 0); }
  70% {
    clip: rect(85px, 9999px, 58px, 0); }
  75% {
    clip: rect(46px, 9999px, 71px, 0); }
  80% {
    clip: rect(6px, 9999px, 64px, 0); }
  85% {
    clip: rect(20px, 9999px, 84px, 0); }
  90% {
    clip: rect(57px, 9999px, 26px, 0); }
  95% {
    clip: rect(36px, 9999px, 92px, 0); }
  100% {
    clip: rect(12px, 9999px, 53px, 0); } }
  #wrapper .error:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 #e74a3b;
    top: 0;
    color: #5a5c69;
    background: #f8f9fc;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse; }

@-webkit-keyframes noise-anim-2 {
  0% {
    clip: rect(68px, 9999px, 1px, 0); }
  5% {
    clip: rect(44px, 9999px, 53px, 0); }
  10% {
    clip: rect(72px, 9999px, 43px, 0); }
  15% {
    clip: rect(18px, 9999px, 16px, 0); }
  20% {
    clip: rect(6px, 9999px, 72px, 0); }
  25% {
    clip: rect(18px, 9999px, 16px, 0); }
  30% {
    clip: rect(93px, 9999px, 46px, 0); }
  35% {
    clip: rect(100px, 9999px, 33px, 0); }
  40% {
    clip: rect(27px, 9999px, 29px, 0); }
  45% {
    clip: rect(5px, 9999px, 64px, 0); }
  50% {
    clip: rect(40px, 9999px, 65px, 0); }
  55% {
    clip: rect(72px, 9999px, 49px, 0); }
  60% {
    clip: rect(44px, 9999px, 39px, 0); }
  65% {
    clip: rect(84px, 9999px, 45px, 0); }
  70% {
    clip: rect(99px, 9999px, 56px, 0); }
  75% {
    clip: rect(87px, 9999px, 20px, 0); }
  80% {
    clip: rect(52px, 9999px, 8px, 0); }
  85% {
    clip: rect(64px, 9999px, 22px, 0); }
  90% {
    clip: rect(51px, 9999px, 69px, 0); }
  95% {
    clip: rect(2px, 9999px, 11px, 0); }
  100% {
    clip: rect(19px, 9999px, 40px, 0); } }

@keyframes noise-anim-2 {
  0% {
    clip: rect(68px, 9999px, 1px, 0); }
  5% {
    clip: rect(44px, 9999px, 53px, 0); }
  10% {
    clip: rect(72px, 9999px, 43px, 0); }
  15% {
    clip: rect(18px, 9999px, 16px, 0); }
  20% {
    clip: rect(6px, 9999px, 72px, 0); }
  25% {
    clip: rect(18px, 9999px, 16px, 0); }
  30% {
    clip: rect(93px, 9999px, 46px, 0); }
  35% {
    clip: rect(100px, 9999px, 33px, 0); }
  40% {
    clip: rect(27px, 9999px, 29px, 0); }
  45% {
    clip: rect(5px, 9999px, 64px, 0); }
  50% {
    clip: rect(40px, 9999px, 65px, 0); }
  55% {
    clip: rect(72px, 9999px, 49px, 0); }
  60% {
    clip: rect(44px, 9999px, 39px, 0); }
  65% {
    clip: rect(84px, 9999px, 45px, 0); }
  70% {
    clip: rect(99px, 9999px, 56px, 0); }
  75% {
    clip: rect(87px, 9999px, 20px, 0); }
  80% {
    clip: rect(52px, 9999px, 8px, 0); }
  85% {
    clip: rect(64px, 9999px, 22px, 0); }
  90% {
    clip: rect(51px, 9999px, 69px, 0); }
  95% {
    clip: rect(2px, 9999px, 11px, 0); }
  100% {
    clip: rect(19px, 9999px, 40px, 0); } }
  #wrapper .error:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 #3f51b5;
    top: 0;
    color: #5a5c69;
    background: #f8f9fc;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse; }
  #wrapper footer.sticky-footer {
    padding: 2rem 0;
    /*-ms-flex-negative: 0;*/
    flex-shrink: 0;
    -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15); }
  #wrapper footer.sticky-footer .copyright {
    line-height: 1;
    font-size: .8rem; }
  #wrapper footer.sticky-footer .copyright a {
    color: #455a64; }
  #wrapper footer.sticky-footer .copyright a:hover {
    text-decoration: none; }
  #wrapper body.sidebar-toggled footer.sticky-footer {
    width: 100%; }
  #wrapper .bg-blue {
    background-color: #e8eaf6 !important; }
  #wrapper .table-flush td,
  #wrapper .table-flush th {
    border-right: 0;
    border-left: 0; }
  #wrapper .table-flush tbody tr:first-child td,
  #wrapper .table-flush tbody tr:first-child th {
    border-top: 0; }
  #wrapper .table-flush tbody tr:last-child td,
  #wrapper .table-flush tbody tr:last-child th {
    border-bottom: 0; }
  #wrapper .card .table {
    margin-bottom: 0; }
  #wrapper .card .table td,
  #wrapper .card .table th {
    padding-right: 1.5rem;
    padding-left: 1.5rem; }
  #wrapper .customer-message {
    border-bottom: 1px solid #e3e6f0;
    padding-bottom: 10px;
    padding-top: 10px; }
  #wrapper .customer-message:hover {
    background-color: #f8f9fc; }
  #wrapper .customer-message a .message-title {
    padding-right: 1.3rem;
    padding-left: 1.3rem; }
  #wrapper .customer-message a .message-time {
    padding-right: 1.3rem;
    padding-left: 1.3rem; }
  #wrapper .customer-message a:hover {
    text-decoration: none; }
  #wrapper .btn-outline-light {
    color: #373840; }
  #wrapper .card {
    -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15); }
  #wrapper .input-group-append .btn {
    -webkit-box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important; }
  #wrapper .input-group-append .btn:hover {
    -webkit-box-shadow: 0 0.125rem 0.3rem 0 rgba(58, 59, 69, 0.4) !important;
    box-shadow: 0 0.125rem 0.3rem 0 rgba(58, 59, 69, 0.4) !important; }
  #wrapper .form-control:focus {
    border: 1px solid #6777ef; }
  #wrapper .input-group-prepend span {
    -webkit-box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }

.react-dropdown-select-input {
  flex-grow: 1; }

.react-dropdown-select-dropdown {
  width: 100% !important; }
