@charset "UTF-8";
@import url("https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@2.0/nanumsquare.css");
@font-face {
  font-family: 'BMDOHYEON';
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMDOHYEON.woff") format("woff"); }

@font-face {
  font-family: 'S-CoreDream-3Light';
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

* {
  scroll-behavior: smooth; }

input,
select,
textarea,
button {
  outline: none !important;
  /* 폼 요소 선택 시 생기는 기본 아웃라인 제거 */ }

p {
  word-break: keep-all;
  white-space: break-spaces;
  word-wrap: break-word;
  margin: 0;
  padding: 0; }

ul, ol, li {
  list-style: none;
  margin: 0;
  padding: 0; }

a {
  word-break: break-all;
  text-decoration: none !important; }

h1, h2, h3, h4, h5, h6 {
  word-break: keep-all;
  white-space: break-spaces;
  word-wrap: break-word;
  margin: 0;
  padding: 0; }

.z-index-1 {
  z-index: 1 !important; }

.z-index-2 {
  z-index: 2 !important; }

.z-index-3 {
  z-index: 3 !important; }

.z-index-4 {
  z-index: 4 !important; }

.z-index-5 {
  z-index: 5 !important; }

.z-index-6 {
  z-index: 6 !important; }

.z-index-7 {
  z-index: 7 !important; }

.z-index-8 {
  z-index: 8 !important; }

.z-index-9 {
  z-index: 9 !important; }

.z-index-10 {
  z-index: 10 !important; }

.z-index-11 {
  z-index: 11 !important; }

.z-index-12 {
  z-index: 12 !important; }

.z-index-13 {
  z-index: 13 !important; }

.z-index-14 {
  z-index: 14 !important; }

.z-index-15 {
  z-index: 15 !important; }

.z-index-16 {
  z-index: 16 !important; }

.z-index-17 {
  z-index: 17 !important; }

.z-index-18 {
  z-index: 18 !important; }

.z-index-19 {
  z-index: 19 !important; }

.z-index-20 {
  z-index: 20 !important; }

.z-index-21 {
  z-index: 21 !important; }

.z-index-22 {
  z-index: 22 !important; }

.z-index-23 {
  z-index: 23 !important; }

.z-index-24 {
  z-index: 24 !important; }

.z-index-25 {
  z-index: 25 !important; }

.z-index-26 {
  z-index: 26 !important; }

.z-index-27 {
  z-index: 27 !important; }

.z-index-28 {
  z-index: 28 !important; }

.z-index-29 {
  z-index: 29 !important; }

.z-index-30 {
  z-index: 30 !important; }

.z-index-31 {
  z-index: 31 !important; }

.z-index-32 {
  z-index: 32 !important; }

.z-index-33 {
  z-index: 33 !important; }

.z-index-34 {
  z-index: 34 !important; }

.z-index-35 {
  z-index: 35 !important; }

.z-index-36 {
  z-index: 36 !important; }

.z-index-37 {
  z-index: 37 !important; }

.z-index-38 {
  z-index: 38 !important; }

.z-index-39 {
  z-index: 39 !important; }

.z-index-40 {
  z-index: 40 !important; }

.z-index-41 {
  z-index: 41 !important; }

.z-index-42 {
  z-index: 42 !important; }

.z-index-43 {
  z-index: 43 !important; }

.z-index-44 {
  z-index: 44 !important; }

.z-index-45 {
  z-index: 45 !important; }

.z-index-46 {
  z-index: 46 !important; }

.z-index-47 {
  z-index: 47 !important; }

.z-index-48 {
  z-index: 48 !important; }

.z-index-49 {
  z-index: 49 !important; }

.z-index-50 {
  z-index: 50 !important; }

.z-index-51 {
  z-index: 51 !important; }

.z-index-52 {
  z-index: 52 !important; }

.z-index-53 {
  z-index: 53 !important; }

.z-index-54 {
  z-index: 54 !important; }

.z-index-55 {
  z-index: 55 !important; }

.z-index-56 {
  z-index: 56 !important; }

.z-index-57 {
  z-index: 57 !important; }

.z-index-58 {
  z-index: 58 !important; }

.z-index-59 {
  z-index: 59 !important; }

.z-index-60 {
  z-index: 60 !important; }

.z-index-61 {
  z-index: 61 !important; }

.z-index-62 {
  z-index: 62 !important; }

.z-index-63 {
  z-index: 63 !important; }

.z-index-64 {
  z-index: 64 !important; }

.z-index-65 {
  z-index: 65 !important; }

.z-index-66 {
  z-index: 66 !important; }

.z-index-67 {
  z-index: 67 !important; }

.z-index-68 {
  z-index: 68 !important; }

.z-index-69 {
  z-index: 69 !important; }

.z-index-70 {
  z-index: 70 !important; }

.z-index-71 {
  z-index: 71 !important; }

.z-index-72 {
  z-index: 72 !important; }

.z-index-73 {
  z-index: 73 !important; }

.z-index-74 {
  z-index: 74 !important; }

.z-index-75 {
  z-index: 75 !important; }

.z-index-76 {
  z-index: 76 !important; }

.z-index-77 {
  z-index: 77 !important; }

.z-index-78 {
  z-index: 78 !important; }

.z-index-79 {
  z-index: 79 !important; }

.z-index-80 {
  z-index: 80 !important; }

.z-index-81 {
  z-index: 81 !important; }

.z-index-82 {
  z-index: 82 !important; }

.z-index-83 {
  z-index: 83 !important; }

.z-index-84 {
  z-index: 84 !important; }

.z-index-85 {
  z-index: 85 !important; }

.z-index-86 {
  z-index: 86 !important; }

.z-index-87 {
  z-index: 87 !important; }

.z-index-88 {
  z-index: 88 !important; }

.z-index-89 {
  z-index: 89 !important; }

.z-index-90 {
  z-index: 90 !important; }

.z-index-91 {
  z-index: 91 !important; }

.z-index-92 {
  z-index: 92 !important; }

.z-index-93 {
  z-index: 93 !important; }

.z-index-94 {
  z-index: 94 !important; }

.z-index-95 {
  z-index: 95 !important; }

.z-index-96 {
  z-index: 96 !important; }

.z-index-97 {
  z-index: 97 !important; }

.z-index-98 {
  z-index: 98 !important; }

.z-index-99 {
  z-index: 99 !important; }

.z-index-100 {
  z-index: 100 !important; }

.transition-1 {
  transition: 0.1s ease-in-out; }

.transition-2 {
  transition: 0.2s ease-in-out; }

.transition-3 {
  transition: 0.3s ease-in-out; }

.transition-4 {
  transition: 0.4s ease-in-out; }

.transition-5 {
  transition: 0.5s ease-in-out; }

.transition-6 {
  transition: 0.6s ease-in-out; }

.transition-7 {
  transition: 0.7s ease-in-out; }

.transition-8 {
  transition: 0.8s ease-in-out; }

.transition-9 {
  transition: 0.9s ease-in-out; }

.transition-10 {
  transition: 1s ease-in-out; }

.transition-11 {
  transition: 1.1s ease-in-out; }

.transition-12 {
  transition: 1.2s ease-in-out; }

.transition-13 {
  transition: 1.3s ease-in-out; }

.transition-14 {
  transition: 1.4s ease-in-out; }

.transition-15 {
  transition: 1.5s ease-in-out; }

.transition-16 {
  transition: 1.6s ease-in-out; }

.transition-17 {
  transition: 1.7s ease-in-out; }

.transition-18 {
  transition: 1.8s ease-in-out; }

.transition-19 {
  transition: 1.9s ease-in-out; }

.transition-20 {
  transition: 2s ease-in-out; }

.transition-21 {
  transition: 2.1s ease-in-out; }

.transition-22 {
  transition: 2.2s ease-in-out; }

.transition-23 {
  transition: 2.3s ease-in-out; }

.transition-24 {
  transition: 2.4s ease-in-out; }

.transition-25 {
  transition: 2.5s ease-in-out; }

.transition-26 {
  transition: 2.6s ease-in-out; }

.transition-27 {
  transition: 2.7s ease-in-out; }

.transition-28 {
  transition: 2.8s ease-in-out; }

.transition-29 {
  transition: 2.9s ease-in-out; }

.transition-30 {
  transition: 3s ease-in-out; }

.border-width-1 {
  border-width: 1px !important; }

.border-width-2 {
  border-width: 2px !important; }

.border-width-3 {
  border-width: 3px !important; }

.border-width-4 {
  border-width: 4px !important; }

.border-width-5 {
  border-width: 5px !important; }

.opacity-10 {
  opacity: 0.1 !important; }

.opacity-20 {
  opacity: 0.2 !important; }

.opacity-30 {
  opacity: 0.3 !important; }

.opacity-40 {
  opacity: 0.4 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-60 {
  opacity: 0.6 !important; }

.opacity-70 {
  opacity: 0.7 !important; }

.opacity-80 {
  opacity: 0.8 !important; }

.opacity-90 {
  opacity: 0.9 !important; }

.opacity-100 {
  opacity: 1 !important; }

.opacity-per-10 {
  opacity: 0.1 !important; }

.border-width-1 {
  border-width: 1px !important; }

.transition-ms-100 {
  transition: 100ms ease-in-out; }

.opacity-per-20 {
  opacity: 0.2 !important; }

.border-width-2 {
  border-width: 2px !important; }

.transition-ms-200 {
  transition: 200ms ease-in-out; }

.opacity-per-30 {
  opacity: 0.3 !important; }

.border-width-3 {
  border-width: 3px !important; }

.transition-ms-300 {
  transition: 300ms ease-in-out; }

.opacity-per-40 {
  opacity: 0.4 !important; }

.border-width-4 {
  border-width: 4px !important; }

.transition-ms-400 {
  transition: 400ms ease-in-out; }

.opacity-per-50 {
  opacity: 0.5 !important; }

.border-width-5 {
  border-width: 5px !important; }

.transition-ms-500 {
  transition: 500ms ease-in-out; }

.opacity-per-60 {
  opacity: 0.6 !important; }

.border-width-6 {
  border-width: 6px !important; }

.transition-ms-600 {
  transition: 600ms ease-in-out; }

.opacity-per-70 {
  opacity: 0.7 !important; }

.border-width-7 {
  border-width: 7px !important; }

.transition-ms-700 {
  transition: 700ms ease-in-out; }

.opacity-per-80 {
  opacity: 0.8 !important; }

.border-width-8 {
  border-width: 8px !important; }

.transition-ms-800 {
  transition: 800ms ease-in-out; }

.opacity-per-90 {
  opacity: 0.9 !important; }

.border-width-9 {
  border-width: 9px !important; }

.transition-ms-900 {
  transition: 900ms ease-in-out; }

.opacity-per-100 {
  opacity: 1 !important; }

.border-width-10 {
  border-width: 10px !important; }

.transition-ms-1000 {
  transition: 1000ms ease-in-out; }

.min-width-unset {
  min-width: unset; }

.max-width-unset {
  max-width: unset; }

.min-height-unset {
  min-height: unset; }

.max-height-unset {
  max-height: unset; }

.min-width-0 {
  min-width: 0px; }

.width-0 {
  width: 0px; }

.max-width-0 {
  max-width: 0px; }

.min-height-0 {
  min-height: 0px; }

.height-0 {
  height: 0px; }

.max-height-0 {
  max-height: 0px; }

.min-width-10 {
  min-width: 10px; }

.width-10 {
  width: 10px; }

.max-width-10 {
  max-width: 10px; }

.min-height-10 {
  min-height: 10px; }

.height-10 {
  height: 10px; }

.max-height-10 {
  max-height: 10px; }

.min-width-20 {
  min-width: 20px; }

.width-20 {
  width: 20px; }

.max-width-20 {
  max-width: 20px; }

.min-height-20 {
  min-height: 20px; }

.height-20 {
  height: 20px; }

.max-height-20 {
  max-height: 20px; }

.min-width-30 {
  min-width: 30px; }

.width-30 {
  width: 30px; }

.max-width-30 {
  max-width: 30px; }

.min-height-30 {
  min-height: 30px; }

.height-30 {
  height: 30px; }

.max-height-30 {
  max-height: 30px; }

.min-width-40 {
  min-width: 40px; }

.width-40 {
  width: 40px; }

.max-width-40 {
  max-width: 40px; }

.min-height-40 {
  min-height: 40px; }

.height-40 {
  height: 40px; }

.max-height-40 {
  max-height: 40px; }

.min-width-50 {
  min-width: 50px; }

.width-50 {
  width: 50px; }

.max-width-50 {
  max-width: 50px; }

.min-height-50 {
  min-height: 50px; }

.height-50 {
  height: 50px; }

.max-height-50 {
  max-height: 50px; }

.min-width-60 {
  min-width: 60px; }

.width-60 {
  width: 60px; }

.max-width-60 {
  max-width: 60px; }

.min-height-60 {
  min-height: 60px; }

.height-60 {
  height: 60px; }

.max-height-60 {
  max-height: 60px; }

.min-width-70 {
  min-width: 70px; }

.width-70 {
  width: 70px; }

.max-width-70 {
  max-width: 70px; }

.min-height-70 {
  min-height: 70px; }

.height-70 {
  height: 70px; }

.max-height-70 {
  max-height: 70px; }

.min-width-80 {
  min-width: 80px; }

.width-80 {
  width: 80px; }

.max-width-80 {
  max-width: 80px; }

.min-height-80 {
  min-height: 80px; }

.height-80 {
  height: 80px; }

.max-height-80 {
  max-height: 80px; }

.min-width-90 {
  min-width: 90px; }

.width-90 {
  width: 90px; }

.max-width-90 {
  max-width: 90px; }

.min-height-90 {
  min-height: 90px; }

.height-90 {
  height: 90px; }

.max-height-90 {
  max-height: 90px; }

.min-width-100 {
  min-width: 100px; }

.width-100 {
  width: 100px; }

.max-width-100 {
  max-width: 100px; }

.min-height-100 {
  min-height: 100px; }

.height-100 {
  height: 100px; }

.max-height-100 {
  max-height: 100px; }

.min-width-110 {
  min-width: 110px; }

.width-110 {
  width: 110px; }

.max-width-110 {
  max-width: 110px; }

.min-height-110 {
  min-height: 110px; }

.height-110 {
  height: 110px; }

.max-height-110 {
  max-height: 110px; }

.min-width-120 {
  min-width: 120px; }

.width-120 {
  width: 120px; }

.max-width-120 {
  max-width: 120px; }

.min-height-120 {
  min-height: 120px; }

.height-120 {
  height: 120px; }

.max-height-120 {
  max-height: 120px; }

.min-width-130 {
  min-width: 130px; }

.width-130 {
  width: 130px; }

.max-width-130 {
  max-width: 130px; }

.min-height-130 {
  min-height: 130px; }

.height-130 {
  height: 130px; }

.max-height-130 {
  max-height: 130px; }

.min-width-140 {
  min-width: 140px; }

.width-140 {
  width: 140px; }

.max-width-140 {
  max-width: 140px; }

.min-height-140 {
  min-height: 140px; }

.height-140 {
  height: 140px; }

.max-height-140 {
  max-height: 140px; }

.min-width-150 {
  min-width: 150px; }

.width-150 {
  width: 150px; }

.max-width-150 {
  max-width: 150px; }

.min-height-150 {
  min-height: 150px; }

.height-150 {
  height: 150px; }

.max-height-150 {
  max-height: 150px; }

.min-width-160 {
  min-width: 160px; }

.width-160 {
  width: 160px; }

.max-width-160 {
  max-width: 160px; }

.min-height-160 {
  min-height: 160px; }

.height-160 {
  height: 160px; }

.max-height-160 {
  max-height: 160px; }

.min-width-170 {
  min-width: 170px; }

.width-170 {
  width: 170px; }

.max-width-170 {
  max-width: 170px; }

.min-height-170 {
  min-height: 170px; }

.height-170 {
  height: 170px; }

.max-height-170 {
  max-height: 170px; }

.min-width-180 {
  min-width: 180px; }

.width-180 {
  width: 180px; }

.max-width-180 {
  max-width: 180px; }

.min-height-180 {
  min-height: 180px; }

.height-180 {
  height: 180px; }

.max-height-180 {
  max-height: 180px; }

.min-width-190 {
  min-width: 190px; }

.width-190 {
  width: 190px; }

.max-width-190 {
  max-width: 190px; }

.min-height-190 {
  min-height: 190px; }

.height-190 {
  height: 190px; }

.max-height-190 {
  max-height: 190px; }

.min-width-200 {
  min-width: 200px; }

.width-200 {
  width: 200px; }

.max-width-200 {
  max-width: 200px; }

.min-height-200 {
  min-height: 200px; }

.height-200 {
  height: 200px; }

.max-height-200 {
  max-height: 200px; }

.min-width-210 {
  min-width: 210px; }

.width-210 {
  width: 210px; }

.max-width-210 {
  max-width: 210px; }

.min-height-210 {
  min-height: 210px; }

.height-210 {
  height: 210px; }

.max-height-210 {
  max-height: 210px; }

.min-width-220 {
  min-width: 220px; }

.width-220 {
  width: 220px; }

.max-width-220 {
  max-width: 220px; }

.min-height-220 {
  min-height: 220px; }

.height-220 {
  height: 220px; }

.max-height-220 {
  max-height: 220px; }

.min-width-230 {
  min-width: 230px; }

.width-230 {
  width: 230px; }

.max-width-230 {
  max-width: 230px; }

.min-height-230 {
  min-height: 230px; }

.height-230 {
  height: 230px; }

.max-height-230 {
  max-height: 230px; }

.min-width-240 {
  min-width: 240px; }

.width-240 {
  width: 240px; }

.max-width-240 {
  max-width: 240px; }

.min-height-240 {
  min-height: 240px; }

.height-240 {
  height: 240px; }

.max-height-240 {
  max-height: 240px; }

.min-width-250 {
  min-width: 250px; }

.width-250 {
  width: 250px; }

.max-width-250 {
  max-width: 250px; }

.min-height-250 {
  min-height: 250px; }

.height-250 {
  height: 250px; }

.max-height-250 {
  max-height: 250px; }

.min-width-260 {
  min-width: 260px; }

.width-260 {
  width: 260px; }

.max-width-260 {
  max-width: 260px; }

.min-height-260 {
  min-height: 260px; }

.height-260 {
  height: 260px; }

.max-height-260 {
  max-height: 260px; }

.min-width-270 {
  min-width: 270px; }

.width-270 {
  width: 270px; }

.max-width-270 {
  max-width: 270px; }

.min-height-270 {
  min-height: 270px; }

.height-270 {
  height: 270px; }

.max-height-270 {
  max-height: 270px; }

.min-width-280 {
  min-width: 280px; }

.width-280 {
  width: 280px; }

.max-width-280 {
  max-width: 280px; }

.min-height-280 {
  min-height: 280px; }

.height-280 {
  height: 280px; }

.max-height-280 {
  max-height: 280px; }

.min-width-290 {
  min-width: 290px; }

.width-290 {
  width: 290px; }

.max-width-290 {
  max-width: 290px; }

.min-height-290 {
  min-height: 290px; }

.height-290 {
  height: 290px; }

.max-height-290 {
  max-height: 290px; }

.min-width-300 {
  min-width: 300px; }

.width-300 {
  width: 300px; }

.max-width-300 {
  max-width: 300px; }

.min-height-300 {
  min-height: 300px; }

.height-300 {
  height: 300px; }

.max-height-300 {
  max-height: 300px; }

.min-width-310 {
  min-width: 310px; }

.width-310 {
  width: 310px; }

.max-width-310 {
  max-width: 310px; }

.min-height-310 {
  min-height: 310px; }

.height-310 {
  height: 310px; }

.max-height-310 {
  max-height: 310px; }

.min-width-320 {
  min-width: 320px; }

.width-320 {
  width: 320px; }

.max-width-320 {
  max-width: 320px; }

.min-height-320 {
  min-height: 320px; }

.height-320 {
  height: 320px; }

.max-height-320 {
  max-height: 320px; }

.min-width-330 {
  min-width: 330px; }

.width-330 {
  width: 330px; }

.max-width-330 {
  max-width: 330px; }

.min-height-330 {
  min-height: 330px; }

.height-330 {
  height: 330px; }

.max-height-330 {
  max-height: 330px; }

.min-width-340 {
  min-width: 340px; }

.width-340 {
  width: 340px; }

.max-width-340 {
  max-width: 340px; }

.min-height-340 {
  min-height: 340px; }

.height-340 {
  height: 340px; }

.max-height-340 {
  max-height: 340px; }

.min-width-350 {
  min-width: 350px; }

.width-350 {
  width: 350px; }

.max-width-350 {
  max-width: 350px; }

.min-height-350 {
  min-height: 350px; }

.height-350 {
  height: 350px; }

.max-height-350 {
  max-height: 350px; }

.min-width-360 {
  min-width: 360px; }

.width-360 {
  width: 360px; }

.max-width-360 {
  max-width: 360px; }

.min-height-360 {
  min-height: 360px; }

.height-360 {
  height: 360px; }

.max-height-360 {
  max-height: 360px; }

.min-width-370 {
  min-width: 370px; }

.width-370 {
  width: 370px; }

.max-width-370 {
  max-width: 370px; }

.min-height-370 {
  min-height: 370px; }

.height-370 {
  height: 370px; }

.max-height-370 {
  max-height: 370px; }

.min-width-380 {
  min-width: 380px; }

.width-380 {
  width: 380px; }

.max-width-380 {
  max-width: 380px; }

.min-height-380 {
  min-height: 380px; }

.height-380 {
  height: 380px; }

.max-height-380 {
  max-height: 380px; }

.min-width-390 {
  min-width: 390px; }

.width-390 {
  width: 390px; }

.max-width-390 {
  max-width: 390px; }

.min-height-390 {
  min-height: 390px; }

.height-390 {
  height: 390px; }

.max-height-390 {
  max-height: 390px; }

.min-width-400 {
  min-width: 400px; }

.width-400 {
  width: 400px; }

.max-width-400 {
  max-width: 400px; }

.min-height-400 {
  min-height: 400px; }

.height-400 {
  height: 400px; }

.max-height-400 {
  max-height: 400px; }

.min-width-410 {
  min-width: 410px; }

.width-410 {
  width: 410px; }

.max-width-410 {
  max-width: 410px; }

.min-height-410 {
  min-height: 410px; }

.height-410 {
  height: 410px; }

.max-height-410 {
  max-height: 410px; }

.min-width-420 {
  min-width: 420px; }

.width-420 {
  width: 420px; }

.max-width-420 {
  max-width: 420px; }

.min-height-420 {
  min-height: 420px; }

.height-420 {
  height: 420px; }

.max-height-420 {
  max-height: 420px; }

.min-width-430 {
  min-width: 430px; }

.width-430 {
  width: 430px; }

.max-width-430 {
  max-width: 430px; }

.min-height-430 {
  min-height: 430px; }

.height-430 {
  height: 430px; }

.max-height-430 {
  max-height: 430px; }

.min-width-440 {
  min-width: 440px; }

.width-440 {
  width: 440px; }

.max-width-440 {
  max-width: 440px; }

.min-height-440 {
  min-height: 440px; }

.height-440 {
  height: 440px; }

.max-height-440 {
  max-height: 440px; }

.min-width-450 {
  min-width: 450px; }

.width-450 {
  width: 450px; }

.max-width-450 {
  max-width: 450px; }

.min-height-450 {
  min-height: 450px; }

.height-450 {
  height: 450px; }

.max-height-450 {
  max-height: 450px; }

.min-width-460 {
  min-width: 460px; }

.width-460 {
  width: 460px; }

.max-width-460 {
  max-width: 460px; }

.min-height-460 {
  min-height: 460px; }

.height-460 {
  height: 460px; }

.max-height-460 {
  max-height: 460px; }

.min-width-470 {
  min-width: 470px; }

.width-470 {
  width: 470px; }

.max-width-470 {
  max-width: 470px; }

.min-height-470 {
  min-height: 470px; }

.height-470 {
  height: 470px; }

.max-height-470 {
  max-height: 470px; }

.min-width-480 {
  min-width: 480px; }

.width-480 {
  width: 480px; }

.max-width-480 {
  max-width: 480px; }

.min-height-480 {
  min-height: 480px; }

.height-480 {
  height: 480px; }

.max-height-480 {
  max-height: 480px; }

.min-width-490 {
  min-width: 490px; }

.width-490 {
  width: 490px; }

.max-width-490 {
  max-width: 490px; }

.min-height-490 {
  min-height: 490px; }

.height-490 {
  height: 490px; }

.max-height-490 {
  max-height: 490px; }

.min-width-500 {
  min-width: 500px; }

.width-500 {
  width: 500px; }

.max-width-500 {
  max-width: 500px; }

.min-height-500 {
  min-height: 500px; }

.height-500 {
  height: 500px; }

.max-height-500 {
  max-height: 500px; }

.min-width-510 {
  min-width: 510px; }

.width-510 {
  width: 510px; }

.max-width-510 {
  max-width: 510px; }

.min-height-510 {
  min-height: 510px; }

.height-510 {
  height: 510px; }

.max-height-510 {
  max-height: 510px; }

.min-width-520 {
  min-width: 520px; }

.width-520 {
  width: 520px; }

.max-width-520 {
  max-width: 520px; }

.min-height-520 {
  min-height: 520px; }

.height-520 {
  height: 520px; }

.max-height-520 {
  max-height: 520px; }

.min-width-530 {
  min-width: 530px; }

.width-530 {
  width: 530px; }

.max-width-530 {
  max-width: 530px; }

.min-height-530 {
  min-height: 530px; }

.height-530 {
  height: 530px; }

.max-height-530 {
  max-height: 530px; }

.min-width-540 {
  min-width: 540px; }

.width-540 {
  width: 540px; }

.max-width-540 {
  max-width: 540px; }

.min-height-540 {
  min-height: 540px; }

.height-540 {
  height: 540px; }

.max-height-540 {
  max-height: 540px; }

.min-width-550 {
  min-width: 550px; }

.width-550 {
  width: 550px; }

.max-width-550 {
  max-width: 550px; }

.min-height-550 {
  min-height: 550px; }

.height-550 {
  height: 550px; }

.max-height-550 {
  max-height: 550px; }

.min-width-560 {
  min-width: 560px; }

.width-560 {
  width: 560px; }

.max-width-560 {
  max-width: 560px; }

.min-height-560 {
  min-height: 560px; }

.height-560 {
  height: 560px; }

.max-height-560 {
  max-height: 560px; }

.min-width-570 {
  min-width: 570px; }

.width-570 {
  width: 570px; }

.max-width-570 {
  max-width: 570px; }

.min-height-570 {
  min-height: 570px; }

.height-570 {
  height: 570px; }

.max-height-570 {
  max-height: 570px; }

.min-width-580 {
  min-width: 580px; }

.width-580 {
  width: 580px; }

.max-width-580 {
  max-width: 580px; }

.min-height-580 {
  min-height: 580px; }

.height-580 {
  height: 580px; }

.max-height-580 {
  max-height: 580px; }

.min-width-590 {
  min-width: 590px; }

.width-590 {
  width: 590px; }

.max-width-590 {
  max-width: 590px; }

.min-height-590 {
  min-height: 590px; }

.height-590 {
  height: 590px; }

.max-height-590 {
  max-height: 590px; }

.min-width-600 {
  min-width: 600px; }

.width-600 {
  width: 600px; }

.max-width-600 {
  max-width: 600px; }

.min-height-600 {
  min-height: 600px; }

.height-600 {
  height: 600px; }

.max-height-600 {
  max-height: 600px; }

.min-width-610 {
  min-width: 610px; }

.width-610 {
  width: 610px; }

.max-width-610 {
  max-width: 610px; }

.min-height-610 {
  min-height: 610px; }

.height-610 {
  height: 610px; }

.max-height-610 {
  max-height: 610px; }

.min-width-620 {
  min-width: 620px; }

.width-620 {
  width: 620px; }

.max-width-620 {
  max-width: 620px; }

.min-height-620 {
  min-height: 620px; }

.height-620 {
  height: 620px; }

.max-height-620 {
  max-height: 620px; }

.min-width-630 {
  min-width: 630px; }

.width-630 {
  width: 630px; }

.max-width-630 {
  max-width: 630px; }

.min-height-630 {
  min-height: 630px; }

.height-630 {
  height: 630px; }

.max-height-630 {
  max-height: 630px; }

.min-width-640 {
  min-width: 640px; }

.width-640 {
  width: 640px; }

.max-width-640 {
  max-width: 640px; }

.min-height-640 {
  min-height: 640px; }

.height-640 {
  height: 640px; }

.max-height-640 {
  max-height: 640px; }

.min-width-650 {
  min-width: 650px; }

.width-650 {
  width: 650px; }

.max-width-650 {
  max-width: 650px; }

.min-height-650 {
  min-height: 650px; }

.height-650 {
  height: 650px; }

.max-height-650 {
  max-height: 650px; }

.min-width-660 {
  min-width: 660px; }

.width-660 {
  width: 660px; }

.max-width-660 {
  max-width: 660px; }

.min-height-660 {
  min-height: 660px; }

.height-660 {
  height: 660px; }

.max-height-660 {
  max-height: 660px; }

.min-width-670 {
  min-width: 670px; }

.width-670 {
  width: 670px; }

.max-width-670 {
  max-width: 670px; }

.min-height-670 {
  min-height: 670px; }

.height-670 {
  height: 670px; }

.max-height-670 {
  max-height: 670px; }

.min-width-680 {
  min-width: 680px; }

.width-680 {
  width: 680px; }

.max-width-680 {
  max-width: 680px; }

.min-height-680 {
  min-height: 680px; }

.height-680 {
  height: 680px; }

.max-height-680 {
  max-height: 680px; }

.min-width-690 {
  min-width: 690px; }

.width-690 {
  width: 690px; }

.max-width-690 {
  max-width: 690px; }

.min-height-690 {
  min-height: 690px; }

.height-690 {
  height: 690px; }

.max-height-690 {
  max-height: 690px; }

.min-width-700 {
  min-width: 700px; }

.width-700 {
  width: 700px; }

.max-width-700 {
  max-width: 700px; }

.min-height-700 {
  min-height: 700px; }

.height-700 {
  height: 700px; }

.max-height-700 {
  max-height: 700px; }

.min-width-710 {
  min-width: 710px; }

.width-710 {
  width: 710px; }

.max-width-710 {
  max-width: 710px; }

.min-height-710 {
  min-height: 710px; }

.height-710 {
  height: 710px; }

.max-height-710 {
  max-height: 710px; }

.min-width-720 {
  min-width: 720px; }

.width-720 {
  width: 720px; }

.max-width-720 {
  max-width: 720px; }

.min-height-720 {
  min-height: 720px; }

.height-720 {
  height: 720px; }

.max-height-720 {
  max-height: 720px; }

.min-width-730 {
  min-width: 730px; }

.width-730 {
  width: 730px; }

.max-width-730 {
  max-width: 730px; }

.min-height-730 {
  min-height: 730px; }

.height-730 {
  height: 730px; }

.max-height-730 {
  max-height: 730px; }

.min-width-740 {
  min-width: 740px; }

.width-740 {
  width: 740px; }

.max-width-740 {
  max-width: 740px; }

.min-height-740 {
  min-height: 740px; }

.height-740 {
  height: 740px; }

.max-height-740 {
  max-height: 740px; }

.min-width-750 {
  min-width: 750px; }

.width-750 {
  width: 750px; }

.max-width-750 {
  max-width: 750px; }

.min-height-750 {
  min-height: 750px; }

.height-750 {
  height: 750px; }

.max-height-750 {
  max-height: 750px; }

.min-width-760 {
  min-width: 760px; }

.width-760 {
  width: 760px; }

.max-width-760 {
  max-width: 760px; }

.min-height-760 {
  min-height: 760px; }

.height-760 {
  height: 760px; }

.max-height-760 {
  max-height: 760px; }

.min-width-770 {
  min-width: 770px; }

.width-770 {
  width: 770px; }

.max-width-770 {
  max-width: 770px; }

.min-height-770 {
  min-height: 770px; }

.height-770 {
  height: 770px; }

.max-height-770 {
  max-height: 770px; }

.min-width-780 {
  min-width: 780px; }

.width-780 {
  width: 780px; }

.max-width-780 {
  max-width: 780px; }

.min-height-780 {
  min-height: 780px; }

.height-780 {
  height: 780px; }

.max-height-780 {
  max-height: 780px; }

.min-width-790 {
  min-width: 790px; }

.width-790 {
  width: 790px; }

.max-width-790 {
  max-width: 790px; }

.min-height-790 {
  min-height: 790px; }

.height-790 {
  height: 790px; }

.max-height-790 {
  max-height: 790px; }

.min-width-800 {
  min-width: 800px; }

.width-800 {
  width: 800px; }

.max-width-800 {
  max-width: 800px; }

.min-height-800 {
  min-height: 800px; }

.height-800 {
  height: 800px; }

.max-height-800 {
  max-height: 800px; }

.min-width-810 {
  min-width: 810px; }

.width-810 {
  width: 810px; }

.max-width-810 {
  max-width: 810px; }

.min-height-810 {
  min-height: 810px; }

.height-810 {
  height: 810px; }

.max-height-810 {
  max-height: 810px; }

.min-width-820 {
  min-width: 820px; }

.width-820 {
  width: 820px; }

.max-width-820 {
  max-width: 820px; }

.min-height-820 {
  min-height: 820px; }

.height-820 {
  height: 820px; }

.max-height-820 {
  max-height: 820px; }

.min-width-830 {
  min-width: 830px; }

.width-830 {
  width: 830px; }

.max-width-830 {
  max-width: 830px; }

.min-height-830 {
  min-height: 830px; }

.height-830 {
  height: 830px; }

.max-height-830 {
  max-height: 830px; }

.min-width-840 {
  min-width: 840px; }

.width-840 {
  width: 840px; }

.max-width-840 {
  max-width: 840px; }

.min-height-840 {
  min-height: 840px; }

.height-840 {
  height: 840px; }

.max-height-840 {
  max-height: 840px; }

.min-width-850 {
  min-width: 850px; }

.width-850 {
  width: 850px; }

.max-width-850 {
  max-width: 850px; }

.min-height-850 {
  min-height: 850px; }

.height-850 {
  height: 850px; }

.max-height-850 {
  max-height: 850px; }

.min-width-860 {
  min-width: 860px; }

.width-860 {
  width: 860px; }

.max-width-860 {
  max-width: 860px; }

.min-height-860 {
  min-height: 860px; }

.height-860 {
  height: 860px; }

.max-height-860 {
  max-height: 860px; }

.min-width-870 {
  min-width: 870px; }

.width-870 {
  width: 870px; }

.max-width-870 {
  max-width: 870px; }

.min-height-870 {
  min-height: 870px; }

.height-870 {
  height: 870px; }

.max-height-870 {
  max-height: 870px; }

.min-width-880 {
  min-width: 880px; }

.width-880 {
  width: 880px; }

.max-width-880 {
  max-width: 880px; }

.min-height-880 {
  min-height: 880px; }

.height-880 {
  height: 880px; }

.max-height-880 {
  max-height: 880px; }

.min-width-890 {
  min-width: 890px; }

.width-890 {
  width: 890px; }

.max-width-890 {
  max-width: 890px; }

.min-height-890 {
  min-height: 890px; }

.height-890 {
  height: 890px; }

.max-height-890 {
  max-height: 890px; }

.min-width-900 {
  min-width: 900px; }

.width-900 {
  width: 900px; }

.max-width-900 {
  max-width: 900px; }

.min-height-900 {
  min-height: 900px; }

.height-900 {
  height: 900px; }

.max-height-900 {
  max-height: 900px; }

.min-width-910 {
  min-width: 910px; }

.width-910 {
  width: 910px; }

.max-width-910 {
  max-width: 910px; }

.min-height-910 {
  min-height: 910px; }

.height-910 {
  height: 910px; }

.max-height-910 {
  max-height: 910px; }

.min-width-920 {
  min-width: 920px; }

.width-920 {
  width: 920px; }

.max-width-920 {
  max-width: 920px; }

.min-height-920 {
  min-height: 920px; }

.height-920 {
  height: 920px; }

.max-height-920 {
  max-height: 920px; }

.min-width-930 {
  min-width: 930px; }

.width-930 {
  width: 930px; }

.max-width-930 {
  max-width: 930px; }

.min-height-930 {
  min-height: 930px; }

.height-930 {
  height: 930px; }

.max-height-930 {
  max-height: 930px; }

.min-width-940 {
  min-width: 940px; }

.width-940 {
  width: 940px; }

.max-width-940 {
  max-width: 940px; }

.min-height-940 {
  min-height: 940px; }

.height-940 {
  height: 940px; }

.max-height-940 {
  max-height: 940px; }

.min-width-950 {
  min-width: 950px; }

.width-950 {
  width: 950px; }

.max-width-950 {
  max-width: 950px; }

.min-height-950 {
  min-height: 950px; }

.height-950 {
  height: 950px; }

.max-height-950 {
  max-height: 950px; }

.min-width-960 {
  min-width: 960px; }

.width-960 {
  width: 960px; }

.max-width-960 {
  max-width: 960px; }

.min-height-960 {
  min-height: 960px; }

.height-960 {
  height: 960px; }

.max-height-960 {
  max-height: 960px; }

.min-width-970 {
  min-width: 970px; }

.width-970 {
  width: 970px; }

.max-width-970 {
  max-width: 970px; }

.min-height-970 {
  min-height: 970px; }

.height-970 {
  height: 970px; }

.max-height-970 {
  max-height: 970px; }

.min-width-980 {
  min-width: 980px; }

.width-980 {
  width: 980px; }

.max-width-980 {
  max-width: 980px; }

.min-height-980 {
  min-height: 980px; }

.height-980 {
  height: 980px; }

.max-height-980 {
  max-height: 980px; }

.min-width-990 {
  min-width: 990px; }

.width-990 {
  width: 990px; }

.max-width-990 {
  max-width: 990px; }

.min-height-990 {
  min-height: 990px; }

.height-990 {
  height: 990px; }

.max-height-990 {
  max-height: 990px; }

.min-width-1000 {
  min-width: 1000px; }

.width-1000 {
  width: 1000px; }

.max-width-1000 {
  max-width: 1000px; }

.min-height-1000 {
  min-height: 1000px; }

.height-1000 {
  height: 1000px; }

.max-height-1000 {
  max-height: 1000px; }

header {
  font-family: 'NanumSquare', serif; }

header#plain {
  padding-bottom: 0 !important; }

.cursor_pointer {
  cursor: pointer;
  transition-duration: 0.2s; }
  .cursor_pointer_bg_light:hover {
    background: #fAfafa !important; }
  .cursor_pointer_shadow:hover {
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.125) !important; }
  .cursor_pointer_fw_bold:hover {
    font-weight: bold; }

.ch_height {
  height: 100vh; }

.main-menu > ul > li:hover > a {
  color: #e14d67; }

body {
  overflow-x: hidden; }

main {
  background: #ffffff; }

textarea {
  resize: none;
  font-size: 1em; }

.overflow_scroll {
  overflow-y: auto;
  overflow-x: auto; }
  .overflow_scroll::-webkit-scrollbar {
    width: 10px; }
  .overflow_scroll::-webkit-scrollbar-thumb {
    width: 10px;
    background: #aaaaaa; }
  .overflow_scroll::-webkit-scrollbar-track {
    background: #efefef; }

.scroll_hidden::-webkit-scrollbar {
  display: none;
  width: 0; }

.overflow_scroll-Y {
  overflow-y: auto; }
  .overflow_scroll-Y::-webkit-scrollbar {
    width: 3px; }
  .overflow_scroll-Y::-webkit-scrollbar-thumb {
    width: 3px;
    background: #dddddd; }
  .overflow_scroll-Y::-webkit-scrollbar-track {
    background: #efefef; }

.crop-text-3 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: keep-all;
  white-space: break-spaces; }

.crop-text-5 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  word-break: keep-all;
  white-space: break-spaces; }

@media print {
  html, body {
    height: 200mm;
    width: 297mm; }
  .page {
    margin: 0;
    border: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always; } }

.ch_room_height {
  height: calc(100vh - 53px); }

.react-emoji {
  width: auto !important; }

.react-input-emoji--container {
  height: 0 !important;
  width: 0 !important;
  border: none !important; }

.react-input-emoji--input {
  max-height: 100px;
  box-sizing: border-box;
  max-width: 100%; }
  @media screen and (max-width: 960px) {
    .react-input-emoji--input {
      max-width: 100%; } }
  .react-input-emoji--input::-webkit-scrollbar {
    display: none; }

.drag_mask {
  pointer-events: none; }

#chat-tab > .nav-item > .nav-link {
  color: #495057;
  border-color: #dee2e6 #dee2e6 #fff; }

.chUser {
  height: auto;
  visibility: visible;
  opacity: 1; }
  .chUser.disabled {
    overflow: hidden;
    height: 0;
    visibility: visible;
    opacity: 0; }

.opacity-mask {
  background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.2)); }

.main-carousel-mask {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)); }

header {
  padding: 0; }

#top_line {
  height: auto;
  margin: 0;
  padding: 10px 0; }

nav {
  margin: 0 !important; }

#top_tools {
  margin: 0;
  height: 100%; }
  #top_tools li {
    padding: 0; }
    #top_tools li a {
      padding: 5px; }

@media only screen and (min-width: 992px) {
  .main-menu, .main-menu > ul, .main-menu ul li, .main-menu ul li a {
    height: 100% !important; } }

.main-menu ul li a {
  padding: 5px 10px !important;
  line-height: 45px !important; }

ul#top_tools li {
  padding: 0;
  margin-left: 10px; }

#logo {
  margin: 0; }

.list-disc li {
  list-style: disc; }

.carousel-item-box {
  min-height: 220px;
  min-width: 220px;
  box-shadow: 10px 5px 15px 5px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  color: #333;
  animation-name: moveTop; }
  .carousel-item-box ul, .carousel-item-box li {
    list-style: disc; }
  .carousel-item-box p {
    font-size: 0.9em; }
  .carousel-item-box:hover {
    background: #3B44AC !important;
    color: #ffffff !important; }
  .carousel-item-box:nth-of-type(1) {
    animation-duration: .5s; }
  .carousel-item-box:nth-of-type(2) {
    animation-duration: 1s; }
  .carousel-item-box:nth-of-type(3) {
    animation-duration: 1.5s; }
  .carousel-item-box:nth-of-type(4) {
    animation-duration: 2s; }
  .carousel-item-box:nth-of-type(5) {
    animation-duration: 2.5s; }

.text-09 {
  font-size: 0.9em; }

.animation-move-top {
  opacity: 0;
  transform: translateY(100%); }
  .animation-move-top.active {
    animation-fill-mode: forwards;
    animation-name: moveTop; }

.animation-move-left {
  opacity: 0;
  transform: translateX(100%); }
  .animation-move-left.active {
    animation-fill-mode: forwards;
    animation-name: moveLeft; }

.animation-move-right {
  opacity: 0;
  transform: translateX(-100%); }
  .animation-move-right.active {
    animation-fill-mode: forwards;
    animation-name: moveRight; }

.animation-duration-1 {
  animation-duration: .5s; }

.animation-duration-2 {
  animation-duration: 1s; }

.animation-duration-3 {
  animation-duration: 1.5s; }

.animation-duration-4 {
  animation-duration: 2s; }

.animation-duration-5 {
  animation-duration: 2.5s; }

.animation-duration-6 {
  animation-duration: 3s; }

@keyframes moveTop {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  100% {
    opacity: 1;
    transform: translateY(0%); } }

@keyframes moveLeft {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: translateX(0%); } }

@keyframes moveRight {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: translateX(0%); } }

p {
  word-break: keep-all; }

.DragDrop {
  width: 100%; }
  .DragDrop-File {
    width: 100%;
    cursor: pointer;
    transition: 0.12s ease-in; }
    .DragDrop-File-Dragging {
      font-size: 2em;
      font-weight: bold; }
  .DragDrop-Files > div {
    max-width: 300px;
    padding: 8px;
    display: flex;
    justify-content: space-between; }
  .DragDrop-Files-Filter {
    cursor: pointer; }
    .DragDrop-Files-Filter:hover {
      opacity: 0.7; }

.write_editor {
  border: 1px solid #ddd;
  height: 500px;
  overflow: auto;
  padding-left: 10px; }

.markerCustomLabels {
  cursor: pointer;
  background-color: #EFF2FB;
  padding: 7px;
  border-radius: 5px;
  border: none;
  box-shadow: 4px 4px 10px -3px grey;
  font-weight: bold; }
  .markerCustomLabels .active {
    color: powderblue; }

.markerCustomLabels:after {
  border-top: 10px solid #EFF2FB;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 0 solid transparent;
  content: "";
  position: absolute;
  top: 33px;
  left: 45%; }

.markerInfoTitle {
  font-weight: bold;
  white-space: nowrap; }

.markerInfoContent {
  font-weight: bold;
  color: #4a8cdb;
  text-align: center; }

.markerInfoWrap {
  padding: 10px;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 4px 4px 10px -3px grey; }

.markerInfoWrap:after {
  border-top: 10px solid #FFFFFF;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 0 solid transparent;
  content: "";
  position: absolute;
  top: 116px;
  left: 46%; }

.closeOverlayWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px; }

.closeOverlayBtn {
  font-size: 15px;
  font-weight: bold;
  cursor: pointer; }

.custom_notify_position {
  left: 50px;
  bottom: -20px; }

.noneSmoothScroll {
  scroll-behavior: unset !important; }

.custom_dropdown {
  min-width: 180px;
  left: unset;
  top: unset;
  transform: unset;
  right: 0 !important; }

.rounded-lg {
  border-radius: 10px !important; }

.rounded-xl {
  border-radius: 20px !important; }

.rounded-xl-top {
  border-radius: 20px !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-xl-bottom {
  border-radius: 20px !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important; }

.bg-blue-light {
  background: #edf0f9 !important; }

.bg-dark-light {
  background: #b5babe !important; }

.ad-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 80px; }

.ad-image {
  display: block;
  width: auto;
  height: auto; }

.ad-textBox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.8); }

.ad-container:hover .ad-textBox {
  opacity: 0.7;
  cursor: pointer; }

.ad-text {
  color: #FFFFFF;
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 90%; }

.post img {
  max-width: 100%; }

.line-through {
  text-decoration: line-through; }

.hover_point_not > div {
  display: none; }

.hover_point > div {
  display: none; }

.hover_point:hover {
  color: #3B44AC; }
  .hover_point:hover > div {
    display: block; }

.main_hotdeal_item {
  color: #222222;
  transition: unset; }

.main_hotdeal_item:hover {
  background: #3B44AC;
  font-weight: bold;
  color: #ffffff; }

.main_hotdeal_header:hover {
  background: #666666 !important;
  color: #ffffff; }

.main_hotdeal_item:hover .text-danger {
  color: lightpink !important; }

.modal {
  background: rgba(0, 0, 0, 0.4) !important; }

.modal_background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6); }

.modal_body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: modalMotion 0.4s; }

@media screen and (min-width: 320px) and (max-width: 576px) {
  .modal_body {
    min-width: 320px;
    width: 80%; } }

@media screen and (min-width: 576px) and (max-width: 768px) {
  .modal_body {
    min-width: 460px;
    width: 80%; } }

.line-through {
  text-decoration: line-through; }

@keyframes modalMotion {
  from {
    top: calc(50% + 20px);
    opacity: 0.5; }
  to {
    top: 50%;
    opacity: 1; } }

.ribbon_3.air span {
  background: #5238C2; }

.ribbon_3.fcl span {
  background: #3868C2; }

.ribbon_3.lcl span {
  background: #008489; }

.ellipsis_1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; }

.ellipsis_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; }

.ellipsis_3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; }

.ellipsis_4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; }

.ellipsis_5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5; }

.react-dropdown-select-content span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.custom-owl-nav {
  width: 20px;
  height: calc(100% - 22px);
  background: rgba(0, 0, 0, 0.125);
  border: 0;
  position: absolute;
  top: 0;
  display: inline-block !important;
  transition: .6s;
  margin: 8px 0;
  color: #ffffff; }
  .custom-owl-nav.prev {
    right: calc(100% - 20px); }
  .custom-owl-nav.next {
    left: calc(100% - 20px); }
  .custom-owl-nav:hover {
    background: rgba(0, 0, 0, 0.5); }

.owl-stage-outer {
  width: calc(100% - 40px) !important;
  margin: 0 auto; }

.custom-owl-stage-outer {
  overflow: hidden; }

.custom-owl-nav-circle {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 100%;
  border: 1px solid #dee2e6 !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  position: absolute;
  top: 50%;
  display: inline-block !important;
  transition: .2s;
  color: #6c757d !important;
  margin-top: -15px; }
  .custom-owl-nav-circle.prev {
    right: calc(100% - 15px); }
    .custom-owl-nav-circle.prev > span {
      content: unset !important; }
  .custom-owl-nav-circle.next {
    left: calc(100% - 15px); }
  .custom-owl-nav-circle > i {
    display: inline-block;
    margin-top: 5px; }
  .custom-owl-nav-circle:hover {
    background: rgba(150, 150, 150, 0.4);
    color: #f8f9fa !important; }

.owl-prev-test {
  width: 20px;
  height: calc(100% - 22px);
  background: rgba(0, 0, 0, 0.125);
  border: 0;
  position: absolute;
  top: 0;
  right: 100%;
  display: inline-block !important;
  transition: .6s;
  margin: 8px 0;
  color: #ffffff; }
  .owl-prev-test:hover {
    background: rgba(0, 0, 0, 0.5); }

.owl-next {
  width: 40px;
  height: 40px;
  color: #FFFFFF;
  background: #3B44AC;
  border: 5px solid #FFFFFF;
  position: absolute;
  top: 35%;
  right: -2%;
  display: block !important; }

.owl-prev i, .owl-next i {
  transform: scale(1, 6);
  color: #ccc; }

.css-majisu-InputComponent {
  display: none !important; }

#HotDealManageEdit .react-dropdown-select-dropdown {
  width: 100%; }

#ScheduleManageEdit .react-dropdown-select-dropdown {
  width: 100%; }

.popup-main {
  width: 100%;
  max-width: 600px;
  display: block;
  position: fixed;
  background: white;
  top: 200px;
  right: 100px;
  z-index: 100;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2);
  border-radius: 0.3rem; }

.popup-main-mobile {
  width: 100%;
  position: fixed;
  top: 80px;
  z-index: 100;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2);
  border-radius: 0.3rem; }

.link-content {
  max-height: calc(var(--vh, 1vh) * 100); }

.bg-info-light {
  background: rgba(23, 162, 184, 0.1); }

.text-gbts {
  color: #3b44ac; }

.border-gbts {
  border-color: #3b44ac !important; }

.bg-gbts {
  background: #3b44ac; }

.bg-logiwork {
  background: #dcdcdc; }

#search_comp_detail_dest img {
  max-width: 100%; }

.quill_data_wrap img {
  max-width: 100%; }

.text-gold {
  color: #C5975E; }

.bg-gold {
  background: #C5975E; }

.BM-DH {
  font-family: "BMDOHYEON", sans-serif; }

.NNS {
  font-family: 'NanumSquare', sans-serif; }

.S-CORE {
  font-family: "SCoreDream", 'NanumSquare', sans-serif; }

.font-weight-100 {
  font-weight: 100 !important; }

.font-weight-200 {
  font-weight: 200 !important; }

.font-weight-300 {
  font-weight: 300 !important; }

.font-weight-400 {
  font-weight: 400 !important; }

.font-weight-500 {
  font-weight: 500 !important; }

.font-weight-600 {
  font-weight: 600 !important; }

.font-weight-700 {
  font-weight: 700 !important; }

.font-weight-800 {
  font-weight: 800 !important; }

.font-weight-900 {
  font-weight: 900 !important; }

.text-yellow {
  color: yellow !important; }

@media screen and (min-width: 320px) and (max-width: 576px) {
  #logitalk_landing .h1 {
    font-size: 2em; }
  #logitalk_landing .h2 {
    font-size: 1.8em; }
  #logitalk_landing .h3 {
    font-size: 1.6em; }
  #logitalk_landing .h4 {
    font-size: 1.4em; }
  #logitalk_landing .h5 {
    font-size: 1.2em; }
  #logitalk_landing .h6 {
    font-size: 1.1em; } }

@media screen and (min-width: 576px) and (max-width: 1200px) {
  #logitalk_landing .h1 {
    font-size: 2.2em; }
  #logitalk_landing .h2 {
    font-size: 2.0em; }
  #logitalk_landing .h3 {
    font-size: 1.8em; }
  #logitalk_landing .h4 {
    font-size: 1.6em; }
  #logitalk_landing .h5 {
    font-size: 1.4em; }
  #logitalk_landing .h6 {
    font-size: 1.2em; } }

@media screen and (min-width: 320px) and (max-width: 576px) {
  #logiStory .h5 {
    font-size: 1.2em; } }

@media screen and (min-width: 576px) and (max-width: 1200px) {
  #logiStory .h5 {
    font-size: 1.4em; } }

@media screen and (min-width: 1200px) {
  #logiStory .h5 {
    font-size: 1.3em; } }

.container-xl {
  max-width: 1600px !important; }

#AdvertiseVideoManageEdit .react-dropdown-select-dropdown {
  width: 100%; }

@media screen and (min-width: 320px) and (max-width: 576px) {
  .post_content {
    max-width: 320px; } }

@media screen and (min-width: 576px) and (max-width: 720px) {
  .post_content {
    max-width: 400px; } }

@media screen and (min-width: 720px) and (max-width: 960px) {
  .post_content {
    max-width: 450px; } }

/*============================================================================================*/
/* 5.  UPDATES  */
/*============================================================================================*/
/*-------- 5.1 Ribbons css --------*/
.ribbon_3_sm {
  position: absolute;
  left: -20px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  height: 65px;
  width: 25px;
  text-align: right; }

/*
width: 65px;
  left: -5px;
  top: -5px;
 */
.ribbon_3_sm span {
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  transform: rotate(-45deg);
  display: block;
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #79A70A 100%);
  box-shadow: 0 3px 10px -5px black;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  position: absolute;
  -webkit-transform: rotate(-90deg);
  width: 60px;
  top: 23px;
  left: -20px;
  line-height: 20px;
  font-size: 11px; }

.text-freiscop {
  color: #002060; }

.bg-freiscop {
  background: #002060; }

.bg-freiscop-50 {
  background: rgba(0, 32, 96, 0.5) !important; }

.bg-freiscop-30 {
  background: rgba(0, 32, 96, 0.3) !important; }

.bg-freiscop-20 {
  background: rgba(0, 32, 96, 0.2) !important; }

.bg-freiscop-10 {
  background: rgba(0, 32, 96, 0.1) !important; }

.bg-freiscop-05 {
  background: rgba(0, 32, 96, 0.05) !important; }

.border-freiscop {
  border-color: #002060 !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.btn-hover-freiscop:hover {
  background: #002060 !important; }

.ribbon_3_sm span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #638908;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #638908; }

.ribbon_3_sm span::after {
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #638908;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #638908; }

.ribbon_3_sm.popular span {
  background: linear-gradient(#f14f3f 0%, #cb4335 100%); }

.ribbon_3_sm.popular span::before {
  border-left: 3px solid #a3362a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #a3362a; }

.ribbon_3_sm.popular span::after {
  border-left: 3px solid transparent;
  border-right: 3px solid #a3362a;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #a3362a; }

.ribbon_promo {
  position: absolute;
  right: var(--right, 10px);
  top: var(--top, -3px);
  left: -22px;
  filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.5));
  width: 24px !important; }

.ribbon_promo > .content {
  color: white;
  font-size: 10px;
  text-align: center;
  font-weight: 400;
  background: var(--color, #2ca7d8) linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.25) 100%);
  padding: 8px 2px 4px;
  /*clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);*/
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 8px), 0 100%);
  width: var(--width, 22px);
  min-height: var(--height, 30px);
  transition: clip-path 1s, padding 1s, background 1s; }

.ribbon_promo > .content span {
  writing-mode: vertical-lr;
  text-orientation: upright;
  font-weight: 400; }

.ribbon.slant-up > .content {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 12px), 50% calc(100% - 6px), 0 100%); }

.ribbon.slant-down > .content {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 6px), 0 calc(100% - 12px)); }

.ribbon.down > .content {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px)); }

.ribbon.up > .content {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 8px), 0 100%); }

.ribbon.check > .content {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), 40% 100%, 0 calc(100% - 12px)); }

.font-size-09 {
  font-size: 0.9em !important; }

.font-size-08 {
  font-size: 0.8em !important; }

.font-size-07 {
  font-size: 0.7em !important; }

.font-size-06 {
  font-size: 0.6em !important; }

.font-size-05 {
  font-size: 0.5em !important; }

.font-size-04 {
  font-size: 0.4em !important; }

.font-size-03 {
  font-size: 0.3em !important; }

.font-size-02 {
  font-size: 0.2em !important; }

.font-size-01 {
  font-size: 0.1em !important; }

.h-unset {
  height: unset !important; }

.text-underline {
  text-decoration: underline; }

.rounded-top-left {
  border-top-left-radius: 0.25rem !important; }

.rounded-top-right {
  border-top-right-radius: 0.25rem !important; }

.rounded-bottom-left {
  border-bottom-left-radius: 0.25rem !important; }

.rounded-bottom-right {
  border-bottom-right-radius: 0.25rem !important; }

.demo_d_none {
  display: none !important; }

.custom-select-wrap > div {
  width: 100%; }

.rotate {
  animation: rotate 3s linear infinite;
  transform-origin: 50% 50%; }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }

.underline {
  text-decoration: underline; }

#search_comp_detail_desc img {
  max-width: 100%; }

.react-dropdown-select-dropdown {
  top: 100% !important;
  margin-top: 5px; }

.btn_1 {
  font-family: inherit;
  background: #002060;
  cursor: pointer;
  padding: 7px 20px;
  display: inline-block;
  outline: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-weight: 600;
  color: #fff;
  border: 1px solid #002060; }
  .btn_1.outline {
    background: #fff;
    border-color: #002060;
    color: #002060 !important; }

.btn_1.outline:hover {
  background: #fff !important;
  border-color: #3b44ac;
  color: #3b44ac !important; }

.bg-check {
  background: #fca9f7; }

.min-width-50 {
  min-width: 50px; }

.height-auto {
  height: auto; }

.mh-100vh {
  min-height: 100vh; }

.mh-90vh {
  min-height: 90vh; }

.css-wmw4vi-ReactDropdownSelect {
  min-height: auto !important; }

#info {
  font-family: 'NanumSquare', serif;
  word-spacing: -3px; }
  #info > div {
    max-width: 1300px;
    margin: 0 auto; }
  #info .text-freiscop {
    color: #002060 !important; }
  #info h1, #info h2, #info h3, #info h4, #info h5, #info h6, #info .h1, #info .h2, #info .h3, #info .h4, #info .h5, #info .h6, #info p {
    line-height: 1.4em; }

.lineUp {
  animation: 1s anim-lineUp ease-out; }
  .lineUp-infinite {
    animation-iteration-count: infinite; }

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(25%); }
  20% {
    opacity: 0; }
  50% {
    opacity: 1;
    transform: translateY(0%); }
  100% {
    opacity: 1;
    transform: translateY(0%); } }

.content-text {
  position: relative; }

.content-text h2 {
  color: #fff;
  font-size: 16rem;
  position: absolute;
  font-weight: 900;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.content-text h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 3px #002060; }

.content-text h2:nth-child(2) {
  color: #002060;
  animation: animate 4s ease-in-out infinite; }

.fade-in-box {
  display: inline-block;
  background: yellow;
  padding: 10px;
  animation: fadein 1s;
  -moz-animation: fadein 1s;
  /* Firefox */
  -webkit-animation: fadein 1s;
  /* Safari and Chrome */
  -o-animation: fadein 1s;
  /* Opera */ }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%); }
  50% {
    clip-path: polygon(0% 60%, 15% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%); } }

#corpInfoReference .authorship {
  bottom: 100%;
  right: 100%;
  transform: translate(6px, 6px) rotate(-6deg); }

.main-menu > ul > li > a {
  font-weight: 900;
  text-decoration: none; }
  .main-menu > ul > li > a:hover {
    color: #0097b7 !important; }

.slide-width {
  display: inline-block; }
  .slide-width .slide-width-hidden {
    width: 0;
    overflow: hidden;
    transition: .5s ease !important; }
  .slide-width:hover .slide-width-hidden {
    width: 3rem; }

.gnb-item:hover {
  font-weight: 900 !important;
  color: #002060 !important; }

.markup_div p {
  word-break: normal;
  white-space: normal;
  word-wrap: break-word;
  margin: initial;
  padding: initial; }

.markup_div ul, .markup_div ol, .markup_div li {
  list-style: revert;
  margin: revert;
  padding: revert; }

.markup_div li {
  list-style: revert;
  margin: revert;
  padding: revert; }

.markup_div table, .markup_div th, .markup_div tr, .markup_div td {
  border-collapse: collapse;
  box-sizing: revert;
  border: 1px solid #666666;
  background: #fff; }

.markup_div th {
  background: #6c757d !important;
  color: #fff; }

.markup_div th, .markup_div tr, .markup_div td {
  text-align: right;
  padding: 0.25rem 1rem; }

.markup_div td:nth-of-type(1) {
  font-weight: bold; }

.bg-schedule {
  background: #f5f6f8; }

#videoMain .custom-owl-nav {
  width: unset;
  height: unset;
  background: transparent;
  border: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: .3s;
  margin: unset;
  color: #ffffff;
  font-size: 5rem;
  display: none !important; }
  @media (min-width: 768px) {
    #videoMain .custom-owl-nav {
      display: inline-block !important; } }
  #videoMain .custom-owl-nav.prev {
    right: calc(100% - 90px); }
  #videoMain .custom-owl-nav.next {
    left: calc(100% - 70px); }
  #videoMain .custom-owl-nav:hover {
    color: #17a2b8; }

#videoMain .owl-carousel .owl-video-tn {
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply; }

#videoMain .owl-video-wrapper {
  width: 100%;
  background: #eceef1;
  transition: .3s;
  overflow: hidden;
  height: 100px; }
  @media (min-width: 576px) {
    #videoMain .owl-video-wrapper {
      height: 100px; } }
  @media (min-width: 768px) {
    #videoMain .owl-video-wrapper {
      height: 200px; } }
  @media (min-width: 992px) {
    #videoMain .owl-video-wrapper {
      height: 300px; } }
  @media (min-width: 1200px) {
    #videoMain .owl-video-wrapper {
      height: 400px; } }

#videoMain .owl-stage {
  height: 200px;
  padding: 0 50px; }
  @media (min-width: 576px) {
    #videoMain .owl-stage {
      height: 200px; } }
  @media (min-width: 768px) {
    #videoMain .owl-stage {
      height: 300px; } }
  @media (min-width: 992px) {
    #videoMain .owl-stage {
      height: 400px; } }
  @media (min-width: 1200px) {
    #videoMain .owl-stage {
      height: 500px; } }

#videoMain .owl-item {
  margin-top: 50px; }
  #videoMain .owl-item.active.center {
    margin-top: 0;
    box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.4); }
    #videoMain .owl-item.active.center .owl-video-wrapper {
      height: 200px; }
      @media (min-width: 576px) {
        #videoMain .owl-item.active.center .owl-video-wrapper {
          height: 200px; } }
      @media (min-width: 768px) {
        #videoMain .owl-item.active.center .owl-video-wrapper {
          height: 300px; } }
      @media (min-width: 992px) {
        #videoMain .owl-item.active.center .owl-video-wrapper {
          height: 400px; } }
      @media (min-width: 1200px) {
        #videoMain .owl-item.active.center .owl-video-wrapper {
          height: 500px; } }
    #videoMain .owl-item.active.center iframe {
      height: 100% !important;
      width: 100%; }

.child-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

@media print {
  .comp_info_print_btn {
    display: none !important; } }

.spin-infinite {
  animation: spin 3s linear 0.5s infinite; }

.main-react-select-wrap > div {
  width: 100%; }

.logiwork-btn-normal {
  height: 30px;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  border-color: #ccc;
  background-image: none;
  background-color: #f9f9f9;
  box-shadow: none;
  line-height: 15px; }

.logiwork-btn-color {
  height: 30px;
  line-height: 22px;
  padding: 0 17px;
  color: #3b44ac;
  background-color: #fff;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #3b44ac;
  border-radius: 0.25rem; }
  .logiwork-btn-color.active {
    color: #fff !important;
    background-color: #3b44ac !important;
    border: 1px solid #3b44ac !important; }

.rounded-square {
  padding: 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid #ededed; }

.main-menu ul ul {
  min-width: 120px !important; }
